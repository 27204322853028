import styled from 'styled-components'

export const DescriptionRules = styled.div`
    background-color: #0019ff1a;
    padding: 6px 10px 8px 8px;
    border-radius: 12px;
    color: ${(props) => props.theme.color.black80};
    margin-bottom: 4px;
    width: 100%;
`
export const DescriptionRulesTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    svg {
        width: 20px;
        height: 20px;
    }
`
export const DescriptionRulesTitle = styled.div`
    font-size: 12px;
    font-weight: 600;
    padding-left: 2px;
`
export const DescriptionRulesRow = styled.div`
    padding-top: 2px;
`
export const DescriptionRulesItemWrapper = styled.div`
    padding-left: 4px;
`
export const DescriptionRulesItem = styled.div`
    font-size: 12px;
    font-weight: 400;
    span {
        font-weight: 600;
    }
`
