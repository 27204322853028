import { useEffect, useRef, useState } from 'react'

import { useContextSelector } from 'use-context-selector'

import { CompanyContext } from 'context/company'
import analytics, { AnalyticsEvents } from 'helpers/analytics'
import { useAppDispatch, useAppSelector } from 'hooks'
import { MultiselectItem } from 'types/common.types'
import { ERoleEmployee } from 'types/data.types'

import { LS_FILTERS_KEY, initialFilters } from '../constants'
import { Filters, FiltersTypes } from '../types'

type UseFiltersProps = {
    onChange: (filters: Filters, textFilter?: string) => void
    localStorageKey?: string
    initialFiltersFromProps?: Filters
    isSetDefaultFilters?: boolean
}

export const useFilters = ({
    onChange,
    localStorageKey = LS_FILTERS_KEY,
    initialFiltersFromProps = initialFilters,
    isSetDefaultFilters = true,
}: UseFiltersProps) => {
    const dispatch = useAppDispatch()

    const [isBlankInput, setIsBlankInput] = useState(false)
    const textFilterRef = useRef('')
    const filtersRef = useRef<Filters>(initialFiltersFromProps)

    const offices = useContextSelector(CompanyContext, (value) => value.company?.Offices?.items) || []
    const departments = useContextSelector(CompanyContext, (c) => c.departments)

    const userDepartmentIDs = useAppSelector(({ user }) => user.departmentIDs)
    const userRole = useAppSelector(({ user }) => user.role)

    const isManager = [ERoleEmployee.MANAGER, ERoleEmployee.OWNER].includes(userRole)
    const isTeamLead = userRole === ERoleEmployee.TEAM_LEAD

    useEffect(() => {
        const savedFilters = localStorage.getItem(localStorageKey)

        if (savedFilters) {
            filtersRef.current = JSON.parse(savedFilters)
        } else {
            if (departments?.length && isSetDefaultFilters) {
                const defaultTeams = departments.filter((d) => userDepartmentIDs.includes(d.id))

                filtersRef.current[FiltersTypes.TEAMS] = { items: defaultTeams, isAll: false }
            }
        }

        onChange(filtersRef.current)
    }, [departments, userDepartmentIDs])

    const searchByFilter =
        (filterName: FiltersTypes) => async (selectedItems: Array<MultiselectItem>, allSelected?: boolean) => {
            if (allSelected) {
                /* choose all items */
                filtersRef.current[filterName] = { items: null, isAll: true }
            } else if (selectedItems.length) {
                /* choose some items */
                filtersRef.current[filterName] = { items: selectedItems, isAll: false }
                if (textFilterRef.current) {
                    setIsBlankInput(true)
                }
            } else {
                /* When we reset all items we hide users */
                filtersRef.current[filterName] = { items: null, isAll: false }
            }

            localStorage.setItem(localStorageKey, JSON.stringify(filtersRef.current))

            onChange(filtersRef.current)
        }

    const searchByName = async (value: string) => {
        filtersRef.current[FiltersTypes.TEAMS].isAll = value !== ''
        filtersRef.current[FiltersTypes.OFFICES].isAll = value !== ''
        filtersRef.current[FiltersTypes.STATUSES].isAll = value !== ''

        setIsBlankInput(false)
        textFilterRef.current = value

        onChange(filtersRef.current, value)
        analytics.logEvent(AnalyticsEvents.SEARCH_EMPLOYEE_BY_NAME)
    }

    const searchByTeams = searchByFilter(FiltersTypes.TEAMS)
    const searchByOffices = searchByFilter(FiltersTypes.OFFICES)
    const searchByStatuses = searchByFilter(FiltersTypes.STATUSES)

    return {
        filters: filtersRef.current,
        textFilter: textFilterRef.current,
        isBlankInput,
        searchByTeams,
        searchByOffices,
        searchByStatuses,
        searchByName,
        offices,
        departments,
        isManager,
        isTeamLead,
        userDepartmentIDs,
    }
}
