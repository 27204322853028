import React, { ReactElement, useCallback, useState, useEffect, useRef } from 'react'

import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'

import { FilterSvg } from 'assets/common'
import { CloseBtn } from 'components/atoms'
import SearchInput from 'components/atoms/SearchInput'
import { statusesList } from 'containers/UniversalCalendar/helpers'
import { useResizeDevice } from 'context'
import useClose from 'hooks/useClose'

import { FiltersList } from './FiltersList'
import { useFilters } from './hooks/useFilters'
import * as S from './styles'
import { Filters as TFilters, FiltersTypes } from './types'

type Props = {
    disabled?: boolean
    className?: string
    onChange: (filters: TFilters, textFilter?: string) => void
    localStorageKey?: string
    initialFiltersFromProps?: TFilters
    isSetDefaultFilters?: boolean
    disableAlienTeams?: boolean
}

const Filters = ({
    onChange,
    disabled,
    className,
    localStorageKey,
    initialFiltersFromProps,
    isSetDefaultFilters = true,
    disableAlienTeams = false,
}: Props): ReactElement => {
    const { isMobile } = useResizeDevice()
    const { t, ready } = useTranslation('translation')

    const [isOpen, setIsOpen] = useState(false)
    const [referenceNode, setReferenceNode] = useState<HTMLDivElement | null>(null)
    const [popperNode, setPopperNode] = useState<HTMLDivElement | null>(null)
    const popperCloseRef = useRef<HTMLDivElement | null>(null)

    const {
        styles: popperStyles,
        attributes,
        forceUpdate,
    } = usePopper(referenceNode, popperNode, {
        placement: 'bottom-start',
        strategy: 'fixed',
        modifiers: [
            { name: 'flip', options: { fallbackPlacements: ['top-start'] } },
            { name: 'offset', options: { offset: [0, 8] } },
        ],
    })

    const openHandler = useCallback(() => {
        if (disabled) {
            return
        }
        setIsOpen((prevState) => !prevState)
    }, [disabled])

    const {
        filters,
        searchByName,
        isBlankInput,
        textFilter,
        searchByStatuses,
        searchByOffices,
        searchByTeams,
        offices,
        departments,
        isTeamLead,
        isManager,
        userDepartmentIDs,
    } = useFilters({
        onChange,
        localStorageKey,
        initialFiltersFromProps,
        isSetDefaultFilters,
    })

    // Use the custom useClose hook with the popperCloseRef
    useClose(popperCloseRef, () => setIsOpen(false), !isOpen)

    const selectedFiltersCount = textFilter
        ? 0
        : (filters[FiltersTypes.OFFICES]?.items?.length || 0) +
          (filters[FiltersTypes.TEAMS].items?.length || 0) +
          (filters[FiltersTypes.STATUSES].items?.length || 0)

    useEffect(() => {
        // Force update popper positioning when open state changes
        if (forceUpdate) {
            forceUpdate()
        }
    }, [isOpen, forceUpdate])

    if (!ready) {
        return <></>
    }

    const content = (
        <S.ItemsBox isMobile={isMobile}>
            <S.CloseBtnWrap>
                <CloseBtn onClose={() => setIsOpen(false)} />
            </S.CloseBtnWrap>
            <FiltersList
                isAllFilters={filters.teams.isAll}
                listItems={
                    disableAlienTeams && isTeamLead
                        ? (departments || []).filter((d) => userDepartmentIDs.includes(d.id))
                        : departments || []
                }
                defaultItems={filters[FiltersTypes.TEAMS]?.items || undefined}
                onChange={searchByTeams}
                title={t('Teams', { ns: 'translation' })}
            />
            <FiltersList
                isAllFilters={filters.offices.isAll}
                listItems={offices}
                defaultItems={filters[FiltersTypes.OFFICES]?.items || undefined}
                onChange={searchByOffices}
                title={t('Offices', { ns: 'translation' })}
            />
            {isManager && (
                <FiltersList
                    isAllFilters={filters.statuses.isAll}
                    listItems={statusesList}
                    defaultItems={filters[FiltersTypes.STATUSES]?.items || undefined}
                    onChange={searchByStatuses}
                    title={t('Status')}
                />
            )}
        </S.ItemsBox>
    )

    return (
        <>
            <SearchInput
                isBlankInput={isBlankInput}
                onInput={searchByName}
                iconStyles={S.IconStyle}
                placeholder={t('Search people') as string}
            />
            <S.DropDownContainer disabled={disabled} className={className}>
                <S.DropDownInterfaceWrapper ref={setReferenceNode} onClick={openHandler}>
                    <S.ArrowWrapper className="flex-center" disabled={disabled}>
                        {selectedFiltersCount !== 0 && (
                            <S.TeamsCount className="flex-center">{selectedFiltersCount}</S.TeamsCount>
                        )}
                        <FilterSvg />
                    </S.ArrowWrapper>
                </S.DropDownInterfaceWrapper>
                {isMobile && isOpen ? (
                    <div>{content}</div>
                ) : (
                    ReactDOM.createPortal(
                        <div
                            ref={(node) => {
                                setPopperNode(node)
                                popperCloseRef.current = node
                            }}
                            style={{
                                ...popperStyles.popper,
                                zIndex: 20000,
                                display: isOpen ? 'flex' : 'none',
                            }}
                            {...attributes.popper}
                        >
                            {content}
                        </div>,
                        document.body,
                    )
                )}
            </S.DropDownContainer>
        </>
    )
}

export default Filters
