import React, { FC, useState, useEffect } from 'react'

import { CrossSVG } from 'assets/common'

import * as S from './styles'
import { TTag, TThemeTag } from '../../../types'

type TProps = {
    onDelete?: (tag: TTag) => void
    handleClick?: (tag: TTag) => void
    tag: TTag
    theme?: TThemeTag
    hasTag?: boolean
    hasCheckbox?: boolean
}

const Tag: FC<TProps> = ({ hasCheckbox, onDelete, tag, hasTag, handleClick, theme }) => {
    const [isChecked, setIsChecked] = useState(hasTag)

    useEffect(() => {
        if (hasCheckbox) {
            setIsChecked(hasTag || false)
        }
    }, [hasCheckbox, hasTag])

    const handleCheck = (tagToCheck) => {
        setIsChecked(!isChecked)
        handleClick && handleClick(tagToCheck)
    }

    return (
        <S.Tag className={`${theme} ${hasTag && 'hasTag'} single-tag`}>
            {hasCheckbox && <input type="checkbox" checked={isChecked} onChange={() => handleCheck(tag)} />}
            <S.TagTitle onClick={() => handleClick && handleClick(tag)}>{tag.name ? tag.name : ''}</S.TagTitle>
            {onDelete && (
                <S.TagButtonDelete onClick={() => onDelete && onDelete(tag)}>
                    <CrossSVG />
                </S.TagButtonDelete>
            )}
        </S.Tag>
    )
}

export default Tag
