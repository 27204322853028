import React, { FC } from 'react'

import { Outlet } from 'react-router-dom'

import Advantages from './components/Advantages'
import CapterraRate from './components/CapterraRate'
import TechTimeQuote from './components/TechTimeQuote'
import TrustedBy from './components/TrustedBy'
import * as S from './styles'
import AuthFooter from '../../atoms/AuthFooter'
import AuthHeader from '../../atoms/AuthHeader'

const AuthRouteWrapp: FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <S.AuthRoot>
            <S.AuthRouteWrappContent>
                <S.AuthRoutFormContent>
                    {children ? (
                        children
                    ) : (
                        <>
                            <AuthHeader />
                            <Outlet />
                            <AuthFooter />
                        </>
                    )}
                </S.AuthRoutFormContent>
            </S.AuthRouteWrappContent>
            <S.AuthRouteLogoContainer>
                <TechTimeQuote />
                <Advantages />
                <S.AuthBottomBlock>
                    <CapterraRate />
                    <TrustedBy />
                </S.AuthBottomBlock>
            </S.AuthRouteLogoContainer>
        </S.AuthRoot>
    )
}

export default AuthRouteWrapp
