import styled from 'styled-components'

export const SpaceScreenMembersHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
`

export const SpaceScreenMembersTitleWrap = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    align-items: center;

    svg {
        width: 30px;
        height: auto;
    }

    h4 {
        margin: 0 5px 0 9px;
        font-weight: 500;
        font-size: 14px;
    }
`
