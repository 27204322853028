import React, { forwardRef, useState, ChangeEvent, useEffect } from 'react'

import { TickSVG } from 'assets/common'
import { checkExistsFunction } from 'helpers/utils'

import { CustomCheckBoxRoot } from './styles'

type Props = {
    name: string
    checked?: boolean
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    label?: string
    disabled?: boolean
}

const CheckBox = forwardRef<HTMLInputElement, Props>(({ name, checked, onChange, label, disabled }, forwardedRef) => {
    const [isChecked, setIsChecked] = useState(checked)

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked)
        checkExistsFunction(onChange)(e)
    }

    return (
        <CustomCheckBoxRoot disabled={disabled} className="checkbox-root">
            {label}
            <input
                ref={forwardedRef}
                name={name}
                checked={isChecked}
                disabled={disabled}
                onChange={disabled ? undefined : changeHandler}
                type="checkbox"
            />
            <span className="flex-center" aria-disabled={disabled}>
                {isChecked && <TickSVG />}
            </span>
        </CustomCheckBoxRoot>
    )
})

CheckBox.displayName = 'CheckBox'
export default CheckBox
