import { useCallback, useEffect, useRef, useState } from 'react'

import { useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import sortBy from 'lodash/sortBy'

import { Filters, FiltersTypes } from 'components/widgets/Filters/types'
import { EmployeeListItem } from 'containers/Employees/types'
import { useAuth } from 'context'
import {
    GetListOfEmployeesQuery,
    GetListOfEmployeesQueryVariables,
    SearchableEmployeeSortableFields,
    SearchableSortDirection,
} from 'graphql/autogenerate/schemas'
import {
    QUERY_GET_SEARCH_EMPLOYEES,
    SUBSCRIPTION_UPDATE_EMPLOYEE,
    SUBSCRIPTION_CREATE_EMPLOYEE,
    SUBSCRIPTION_DELETE_EMPLOYEE,
} from 'graphql/queries/employees/graphql'
import { generateFilterForSearchEmployees } from 'helpers/generateFilterForSearchEmployees'
import { useReconnectingSubscription } from 'helpers/useReconnectingSubscription'
import { useAppDispatch, useAppSelector } from 'hooks'
import { messageActions } from 'store/slices/message'
import { NextToken } from 'types/common.types'
import { EUserGroup } from 'types/user.types'

import { initialFilters } from '../constants'

type UseEmployeesDataProps = {
    companyID: string
    excludeCurrentUser?: boolean
    searchByMyTeams?: boolean
    searchByBookings?: boolean
    isLoadBookings?: boolean
}

export const useEmployeesData = ({
    companyID,
    excludeCurrentUser = true,
    searchByMyTeams = false,
    searchByBookings = false,
    isLoadBookings = false,
}: UseEmployeesDataProps) => {
    const dispatch = useAppDispatch()
    const employee = useAppSelector(({ user }) => ({
        departmentIDs: user.departmentIDs,
        id: user.id,
    }))
    const { userGroup } = useAuth()
    const [employees, setEmployees] = useState<Array<EmployeeListItem>>([])
    const [loading, setLoading] = useState(false)
    const [nextToken, setNextToken] = useState<NextToken>(null)
    const [hasMore, setHasMore] = useState(true)
    const [totalEmployees, setTotalEmployees] = useState(0)

    const dateFilterRef = useRef('')
    const favouriteColleagueIDsRef = useRef<string[]>([])
    const noStatusesFilterRef = useRef(false)
    const userDepartmentIDsRef = useRef<string[]>([])

    const textFilterRef = useRef('')

    const filtersRef = useRef<Filters>(initialFilters)

    const sortConfigRef = useRef<{ field: string; direction: SearchableSortDirection }>({
        field: 'userName',
        direction: SearchableSortDirection.asc,
    })

    const isTeamLead = userGroup === EUserGroup.TEAM_LEAD
    const isManager = userGroup === EUserGroup.MANAGER

    const { refetch: getData } = useQuery<GetListOfEmployeesQuery, GetListOfEmployeesQueryVariables>(
        QUERY_GET_SEARCH_EMPLOYEES,
        {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
            skip: true,
            onError: (err) => dispatch(messageActions.messageShown({ text: err.message, severity: 'error' })),
        },
    )

    useReconnectingSubscription<unknown, { companyID: string }>(SUBSCRIPTION_CREATE_EMPLOYEE, {
        variables: {
            companyID,
        },
        onData: () => {
            subscriptionDebouncedFetch()
        },
    })

    useReconnectingSubscription<{ onUpdateEmployee: EmployeeListItem }, { companyID: string }>(
        SUBSCRIPTION_UPDATE_EMPLOYEE,
        {
            variables: {
                companyID,
            },
            onData: ({ data: { data } }) => {
                const updateEmployee = data?.onUpdateEmployee

                if (updateEmployee) {
                    /*TODO need fixed if @Roy came back*/
                    const update = employees.map((emp) => (emp.id === updateEmployee.id ? updateEmployee : emp))
                    subscriptionDebouncedFetch()
                    //setEmployees(employees.map((emp) => (emp.id === updateEmployee.id ? updateEmployee : emp)))
                }
            },
        },
    )

    useReconnectingSubscription<{ onDeleteEmployee: EmployeeListItem }, { companyID: string }>(
        SUBSCRIPTION_DELETE_EMPLOYEE,
        {
            variables: {
                companyID,
            },
            onData: ({ data: { data } }) => {
                const deleteEmployee = data?.onDeleteEmployee

                if (deleteEmployee) {
                    setEmployees(employees.filter((emp) => emp.id !== deleteEmployee.id))
                }
            },
        },
    )

    const getSortableField = (field: string): SearchableEmployeeSortableFields | undefined => {
        switch (field) {
            case 'userName':
                return SearchableEmployeeSortableFields.fullNameLowerCase
            case 'buddy':
                return SearchableEmployeeSortableFields.buddyID
            case 'position':
                return SearchableEmployeeSortableFields.positionID
            case 'department':
                return SearchableEmployeeSortableFields.departmentIDsString
            case 'role':
                return SearchableEmployeeSortableFields.roleString
            case 'status':
                return SearchableEmployeeSortableFields.active
            case 'birthday':
                return SearchableEmployeeSortableFields.birthday
            default:
                return undefined
        }
    }

    const setDepartmentsFilter = () => {
        if (isManager) {
            const cacheFilters = filtersRef.current

            const textFilter = textFilterRef.current
            return textFilter ? undefined : cacheFilters[FiltersTypes.TEAMS].items || undefined
        }

        if (isTeamLead) {
            return employee.departmentIDs
        }

        if (searchByMyTeams && userDepartmentIDsRef.current) {
            return userDepartmentIDsRef.current
        }

        const cacheFilters = filtersRef.current

        const textFilter = textFilterRef.current
        return textFilter ? undefined : cacheFilters[FiltersTypes.TEAMS].items || undefined
    }

    const searchEmployees = async (token?: NextToken, limit = 10) => {
        const cacheFilters = filtersRef.current
        const textFilter = textFilterRef.current
        const dateFilter = dateFilterRef.current
        const noStatusesFilter = noStatusesFilterRef.current
        const { field, direction } = sortConfigRef.current
        const sortField = getSortableField(field)

        if (!sortField) {
            console.error(`Invalid sort field: ${field}`)
            return
        }

        setLoading(true)

        const departmentsFilter = setDepartmentsFilter()

        const filterForSearchEmployees = generateFilterForSearchEmployees({
            weekNumber: 0,
            startWeekDate: '',
            employeeId: excludeCurrentUser ? employee.id : '',
            filterByNameAndEmail: textFilter,
            companyID,
            departmentData: departmentsFilter,
            officeData: textFilter ? undefined : cacheFilters[FiltersTypes.OFFICES].items || undefined,
            statuses: textFilter ? undefined : cacheFilters[FiltersTypes.STATUSES].items || undefined,
            isManager,
            nextToken: token,
            myFavouriteColleagueIDs: favouriteColleagueIDsRef.current || [],
            noStatusesFilter,
        })

        const data = await getData({
            ...filterForSearchEmployees,
            ...(searchByBookings && {
                filterBookingsByCreatedAt: {
                    date: {
                        beginsWith: dateFilter ? dateFilter : '',
                    },
                },
            }),
            sortName: sortField,
            sortOrder: direction,
            loadBookings: isLoadBookings,
            limit,
        }).finally(() => setLoading(false))

        const sortedEmployees = sortBy(data?.data?.searchEmployees?.items || [], (person) => {
            return person?.BookingsByCreatedAt && person?.BookingsByCreatedAt?.items.length > 0 ? 0 : 1
        })

        const searchEmployee = {
            nextToken: data?.data?.searchEmployees?.nextToken,
            total: data?.data?.searchEmployees?.total,
            ...sortedEmployees,
            items: data?.data?.searchEmployees?.items,
        }

        const { items, nextToken: resNextToken, total } = searchEmployee || { items: [] }

        if (token) {
            setEmployees((state) => state.concat(items as unknown as EmployeeListItem[]))
        } else {
            setEmployees(items as unknown as EmployeeListItem[])
        }

        total !== null && setTotalEmployees(total || 0)
        setNextToken(resNextToken || null)
        setHasMore(!!resNextToken)
    }

    const debouncedFetch = useCallback(
        debounce(async (isLoading = false) => {
            isLoading && setLoading(true)
            await searchEmployees()
            setLoading(false)
        }, 100),
        [],
    )
    const subscriptionDebouncedFetch = useCallback(debounce(searchEmployees, 500), [])

    const searchByFilters = async (filters: Filters, textFilter?: string) => {
        textFilterRef.current = textFilter || ''
        filtersRef.current = filters

        await debouncedFetch(true)
    }

    const searchByTextFilter = async (textFilter: string, date?: string) => {
        textFilterRef.current = textFilter || ''
        dateFilterRef.current = date || ''
        filtersRef.current = { ...initialFilters, [FiltersTypes.STATUSES]: { items: null, isAll: true } }
        await debouncedFetch(true)
    }

    const searchByDateFilter = async (
        noStatuses: boolean,
        departmentsIDs: string[],
        date: string,
        textFilter: string,
        favouriteColleagueIDs: string[],
    ) => {
        dateFilterRef.current = date
        noStatusesFilterRef.current = noStatuses
        userDepartmentIDsRef.current = departmentsIDs
        textFilterRef.current = textFilter || ''

        if (favouriteColleagueIDs) {
            favouriteColleagueIDsRef.current = favouriteColleagueIDs
        }

        await debouncedFetch(true)
    }

    const searchBySortName = async (field: string, direction: SearchableSortDirection) => {
        sortConfigRef.current = { field, direction }
        await debouncedFetch()
    }

    useEffect(() => {
        debouncedFetch(true)
    }, [companyID])

    useEffect(() => {
        resetState()
    }, [])

    const resetState = () => {
        setEmployees([])
        setHasMore(false)
    }

    // Function to fetch more employees for infinite scrolling
    const fetchMoreEmployees = useCallback(async () => {
        if (nextToken && hasMore && !loading) {
            await searchEmployees(nextToken, 10)
        }
    }, [nextToken, hasMore, loading, searchEmployees])

    return {
        employees,
        totalEmployees,
        searchEmployees,
        searchByFilters,
        nextToken,
        hasMore,
        searchBySortName,
        searchByTextFilter,
        searchByDateFilter,
        resetState,
        fetchMoreEmployees,
        loading,
    }
}
