import amplitude from 'amplitude-js'

import isInMsTeams from './isInMsTeams'

export enum AnalyticsEvents {
    LOGIN = 'Logged in',
    LOGOUT = 'Logged out',
    PASSWORD_RESET = 'Password reset link sent',
    COMPANY_REGISTERED = 'Company registered',
    BOOKING_CREATED = 'Booking created',
    BOOKING_UPDATED = 'Booking updated',
    BOOKING_DELETED = 'Booking deleted',
    BOOKING_EDITED = 'Booking edited',
    CALENDAR_VIEWED = 'Calendar screen viewed',
    ROOMS_VIEWED = 'Rooms screen viewed',
    PICKER_VIEWED = 'Booking screen viewed',
    PROFILE_VIEWED = 'Profile screen viewed',
    COVID_VIEWED = 'Covid screen viewed',
    DEMO_ACCOUNT_CREATED = 'Demo account created',
    FREE_TRIAL_STARTED = 'Free trial started',
    OPEN_FILTERS = 'Open filters',
    FILTERS_CHANGE_TIME = 'Change the time in filters',
    FILTERS_SELECT_TAGS = 'Select tags in filters',
    FILTERS_CHANGE_REPEATING = 'Change repeating in filters',
    SEARCH_EMPLOYEE = 'Search employee',
    SEARCH_EMPLOYEE_BY_NAME = 'Search employee by name',
    SET_FAVORITE_SEAT = 'Set favorite seat',
    TURN_ON_TV_MODE = 'Turn on TV mode',
    ADD_TO_FAVOURITES_COLLEAGUES = 'Add to favourites colleagues',
    CALENDAR_EVENT_CREATED = 'Calendar event created',
    CALENDAR_EVENT_DELETED = 'Calendar event deleted',
    CALENDAR_EVENT_EDITED = 'Calendar event edited',
}

export const AnalyticsBookingType = {
    office: { Type: 'office', Source: 'Calendar' },
    home: { Type: 'home', Source: 'Calendar' },
    notAvailable: { Type: 'notAvailable', Source: 'Calendar' },
    team: { Type: 'team', Source: 'Calendar' },
    room: { Type: 'room', Source: 'Calendar' },
}

export const AnalyticsBookingTypeForRoomScreen = {
    office: { Type: 'office', Source: 'RoomScreen' },
    home: { Type: 'home', Source: 'RoomScreen' },
    notAvailable: { Type: 'notAvailable', Source: 'RoomScreen' },
    team: { Type: 'team', Source: 'RoomScreen' },
    room: { Type: 'room', Source: 'RoomScreen' },
}

export const AnalyticsBookingTypeForSpaceScreen = {
    office: { Type: 'office', Source: 'SpaceScreen' },
    home: { Type: 'home', Source: 'SpaceScreen' },
    notAvailable: { Type: 'notAvailable', Source: 'SpaceScreen' },
    team: { Type: 'team', Source: 'SpaceScreen' },
    room: { Type: 'room', Source: 'SpaceScreen' },
    blocked: { Type: 'blocked', Source: 'SpaceScreen' },
}

class Analytics {
    constructor() {
        this.initAmplitude()
    }

    private initAmplitude = () => {
        if (isInMsTeams()) {
            amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, undefined, { platform: 'MS Teams' })
        } else {
            amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, undefined)
        }
    }

    logEvent = (eventName: string, eventProps?: unknown) => {
        // console.log('Logging event:', eventName, eventProps)
        amplitude.getInstance().logEvent(eventName, eventProps)
    }
}

const analytics = new Analytics()
export default analytics
