import styled from 'styled-components'

export const Tag = styled.div`
    display: flex;
    margin: 2px;
    padding: 2px 8px;
    align-items: center;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.color.secondary_white_15};
    color: ${(props) => props.theme.color.white_80};
    background: ${(props) => props.theme.color.main_black};

    &.light {
        color: rgba(51, 51, 51, 0.8);
        border: 1px solid rgba(51, 51, 51, 0.05);
        background: rgba(227, 232, 238, 0.5);
        font-weight: 400;
        border-radius: 8px;

        &.hasTag {
            color: #fff;
        }
    }

    &.lightV2 {
        font-weight: 600;
        font-size: 10px;
        text-transform: uppercase;
        border-radius: 6px;
        color: ${(props) => props.theme.color.black};
        background: ${(props) => props.theme.color.greyBackground};
    }

    &.hasTag {
        border: 1px solid var(--secondary_white_30, rgba(255, 255, 255, 0.3));
        background: var(--main_blue_accent, #0019ff);
    }
`
export const TagTitle = styled.div`
    display: flex;
`
export const TagButtonDelete = styled.button`
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    margin-left: 4px;
    &:hover {
        svg {
            opacity: 1;
        }
    }
    svg {
        width: 16px;
        height: 16px;
        opacity: 30%;
        fill: rgb(255, 255, 255);
        path {
            fill: ${(props) => props.theme.color.white_30};
        }
    }
`

export const SkeletonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    overflow-y: auto;
    width: 100%;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 50px;
    }
    & .skeleton {
        display: flex;
        margin: 2px 4px;
        padding: 2px 4px;
        align-items: center;
        font-size: 12px;
        cursor: default;
        border-radius: 10px;
    }
`
