import React from 'react'

import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip'

import { HelpSVG } from 'assets/common'

import TooltipContainer from '../TooltipContainer'

type HintIconProps = Pick<TooltipProps, 'placement'> & {
    hint: string
    offset?: string
}

const HintIcon = ({ hint, offset = '0, 8px', placement = 'right' }: HintIconProps) => {
    return (
        <TooltipContainer placement={placement} title={hint} offset={offset}>
            <HelpSVG style={{ minWidth: '16px' }} />
        </TooltipContainer>
    )
}

export default HintIcon
