import React, { FC } from 'react'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import * as S from './styles'
import { ITab } from '../../../types/Tabs'
import { TTheme } from '../../../types/Theme'

type TProps = {
    tabs: ITab[]
    currentTab: string
    theme: TTheme
    setCurrentTab: (tab: string) => void
}

const CustomTabs: FC<TProps> = ({ tabs, currentTab, setCurrentTab, theme }) => {
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue)
    }

    return (
        <S.TabsWrapper>
            <Tabs value={currentTab} type={'button'} onChange={handleChange}>
                {tabs.map((tab, index) => (
                    <Tab key={index} value={tab.value} label={tab.label} />
                ))}
            </Tabs>
        </S.TabsWrapper>
    )
}

export default CustomTabs
