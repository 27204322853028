import { gql } from '@apollo/client'

import { MAIN_EMPLOYEE } from 'graphql/dataQuery'

const BOOKING = gql`
    ${MAIN_EMPLOYEE}
    fragment BookingFieldsForSpaceScreen on Booking {
        id
        date
        createdAt
        startTime
        endTime
        reservableID
        isBlocked
        isFullDay
        bookingType
        bookingSubType
        isTeamEvent
        checkInStatus

        Employee {
            ...MainEmployeeFields
        }

        Space {
            id
            name
            floorPlanFilePath
            workingHoursFrom
            workingHoursTo
            officeID
            Office {
                id
                name
            }
        }

        reservable {
            id
            type
            x
            y
            zonePoints
            color
        }
        BookingRequest {
            id
            repeatType
            addParkingSlot
            daysOfWeek
            parkingSlotID
            ParkingSlot {
                Space {
                    id
                }
            }
        }
    }
`

export const QUERY_SEAT_PICKER_GET_PARKING_SPOTS_BY_SPACE_ID = gql`
    ${MAIN_EMPLOYEE}
    query ListParkingSpotsBySpace(
        $id: ID!
        $parkingFilter: ModelBookingReservableFilterInput
        $type: ModelStringKeyConditionInput
    ) {
        listReservablesBySpaceAndType(spaceID: $id, type: $type, limit: 1000) {
            items {
                id
                name
                x
                y
                type
                availabilityType
                maxAmountOfPeople
                zonePoints
                color

                Employee {
                    ...MainEmployeeFields
                }

                Space {
                    id
                    name
                    address
                    workingHoursFrom
                    workingHoursTo
                }

                Department {
                    id
                    name
                }

                ReservableToDepartments {
                    items {
                        Department {
                            id
                            name
                        }
                    }
                }

                ReservableToEmployees {
                    items {
                        employee {
                            id
                            fullName
                            departmentIDs
                            shareAttendance
                        }
                    }
                }

                Tags {
                    items {
                        Tag {
                            id
                            name
                        }
                        reservableID
                    }
                }

                BookingReservablesByCreatedAt(filter: $parkingFilter, limit: 1000) {
                    items {
                        booking {
                            startTime
                            endTime
                            isFullDay
                            isBlocked
                            employeeID
                            Employee {
                                ...MainEmployeeFields
                            }
                        }
                    }
                }
            }
        }
    }
`

export const QUERY_GET_BOOKING_BY_ID = gql`
    ${BOOKING}
    query GetBookingByIDForSpaceScreen($bookingID: ID!) {
        getBooking(id: $bookingID) {
            ...BookingFieldsForSpaceScreen
        }
    }
`

export const QUERY_GET_BOOKING_BY_ID_EMPLOYEE = gql`
    ${BOOKING}
    query GetEmployee(
        $employeeId: ID!
        $bookingStartTime: ModelStringKeyConditionInput
        $filterBooking: ModelBookingFilterInput
    ) {
        getEmployee(id: $employeeId) {
            BookingsByStartTime(startTime: $bookingStartTime, filter: $filterBooking) {
                items {
                    ...BookingFieldsForSpaceScreen
                }
            }
        }
    }
`

export const SUBSCRIPTION_CREATE_BOOKING = gql`
    ${BOOKING}
    subscription OnCreateBookingBySpaceAndDate($companyID: String!, $spaceID: String!, $date: String!) {
        onCreateBookingBySpaceAndDate(companyID: $companyID, spaceID: $spaceID, date: $date) {
            id
            date
            startTime
            endTime
            reservableID
            isBlocked
            isFullDay
            bookingType
            bookingSubType
            isTeamEvent
            checkInStatus
            BookingRequest {
                Bookings {
                    items {
                        ...BookingFieldsForSpaceScreen
                    }
                }
            }
            Employee {
                departmentIDs
                shareAttendance
                departmentsString
                firstname
                fullName
                lastname
                photo
            }
            reservable {
                id
                type
                x
                y
                zonePoints
                color
            }
        }
    }
`

export const SUBSCRIPTION_DELETE_BOOKING = gql`
    subscription OnDeleteBookingBySpaceAndDate($companyID: String!, $spaceID: String!, $date: String!) {
        onDeleteBookingBySpaceAndDate(companyID: $companyID, spaceID: $spaceID, date: $date) {
            id
            date
            startTime
            endTime
            reservableID
            isBlocked
            isFullDay
            bookingType
            bookingSubType
            isTeamEvent
            checkInStatus
        }
    }
`
export const SUBSCRIPTION_DELETE_PARKING = gql`
    subscription OnDeleteBookingReservableByCompany($companyID: String!) {
        onDeleteBookingReservableByCompany(companyID: $companyID) {
            id
            companyID
            date
            reservableID
        }
    }
`
