import React, { FC } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import * as S from './styles'
import { TTheme } from '../../../types/Theme'

type TLoaderProps = {
    size?: number
    theme?: TTheme
}

const Loader: FC<TLoaderProps> = ({ size = 20, theme }) => {
    return (
        <S.Loader size={size} theme={theme}>
            <CircularProgress />
        </S.Loader>
    )
}

export default Loader
