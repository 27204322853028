import moment from 'moment'
import { unitOfTime } from 'moment/moment'

import { AvailabilityType, HorizonOfPlanningTimeUnit, RepeatType, Reservable } from 'graphql/autogenerate/schemas'
import { MultiselectItem, Point } from 'types/common.types'

import { MobileScheduleRepeatType } from '../containers/Calendar/types'
import { EAvailabilityType, TCompany, TEmployee, TReservable } from '../types/data.types'

export const noop = () => {
    return
}

export const noopAsync = async () => {
    return
}

export const search = (str: string, searchValue: string) => str.search(new RegExp(searchValue, 'i')) !== -1

export const getQueryParams = <T extends string>(queries: string, keys: Array<T>) => {
    const searchParams = new URLSearchParams(queries)

    return keys.reduce((acc, key) => {
        const currValue = searchParams.get(key)
        if (currValue) {
            acc[key] = currValue
        }
        return acc
    }, {} as { [key in T]: string })
}

export const capitalizeString = ([first, ...rest]: string) => first.toLocaleUpperCase() + rest.join('')

export const getConvertedDate = (date: string | Date | moment.Moment, format?: string) =>
    moment(date).format(format || 'MMMM D')
export const getConvertedEngLocaleDate = (date: string | Date | moment.Moment, format?: string) =>
    moment(date)
        .locale('en')
        .format(format || 'MMMM D')

export const exhaustiveCheck = (_: never) => {
    return
}

export const getSelectItemKey = (item: MultiselectItem, key: 'name' | 'id' = 'name') =>
    typeof item === 'object' ? item[key] : item

export const getErrorMessage = (error: Error | { message: string } | string) =>
    typeof error !== 'string' ? error.message : error

/**
 * get timeline time from ISODate (HH:mm)
 * **/
export const getHoursFromISODateString = (date: string) => date.split('T')[1].substring(0, 5)
export const getAWSStringFromDateAndTime = (date: string, time: string) => `${date}T${time}:00.000Z`
export const getTimeInHours = (timeString: string): number => {
    const [hours, minutes] = timeString.split(':').map(Number)
    return hours + minutes / 60
}

export const getMinutesFromMidnight = (time: string) => {
    const mnt = moment(time, 'HH:mm')
    const mMidnight = moment('00:00', 'HH:mm')

    return mnt.diff(mMidnight, 'minutes')
}

export const getRangeMinutes = (timeFrom: string, timeTo: string) => {
    const mmntFrom = moment(timeFrom, 'HH:mm')
    const mmntTo = moment(timeTo, 'HH:mm')

    return mmntTo.diff(mmntFrom, 'minutes')
}

export const getStringByMinutes = (minutes: number) => {
    const mMidnight = moment('00:00', 'HH:mm')
    return mMidnight.add(minutes, 'minutes').format('HH:mm')
}

export const getHourStringFromNumber = (hour: number, withHalf = false) => {
    const hourString = `${hour}`.padStart(2, '0')
    const minuteString = withHalf ? '30' : '00'

    return `${hourString}:${minuteString}`
}

export const checkExistsFunction =
    <T extends any[]>(cb?: (...args: T) => void) =>
    (...args: T) => {
        if (typeof cb === 'function') {
            cb(...args)
        }
    }

export const getPartDayTimeString = (time1?: string, time2?: string) => {
    if (!time1 || !time2) {
        return ''
    }

    return `${getHoursFromISODateString(time1)} - ${getHoursFromISODateString(time2)}`
}

export const getCookie = (name: string) => {
    const cookieArr = document.cookie.split('; ')

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split('=')

        if (name === cookiePair[0]) {
            return decodeURIComponent(cookiePair[1])
        }
    }

    // Return null if the cookie wasn't found
    return null
}

export const findOverlap = (arr1: string[], arr2: string[]) => {
    const set2 = new Set(arr2)
    return arr1.filter((item) => set2.has(item))
}

export const checkRoomIsAvailableForEmployee = (
    room: Pick<TReservable, 'employeeID' | 'availabilityType'> & {
        ReservableToDepartments: { items: { Department?: { id: string } }[] }
    },
    employee: Pick<TEmployee, 'id' | 'departmentIDs'>,
) => {
    switch (room.availabilityType) {
        case EAvailabilityType.BOOKED_FOR_PERSON: {
            return room.employeeID === employee.id
        }
        case EAvailabilityType.BOOKED_FOR_TEAM: {
            return (
                findOverlap(
                    (room.ReservableToDepartments?.items || [])
                        .filter((it) => !!it.Department)
                        .map((it) => it.Department!.id!) || [],
                    employee.departmentIDs || [],
                ).length > 0
            )
        }
        case EAvailabilityType.UNAVALIABLE: {
            return false
        }
        default: {
            return true
        }
    }
}

export const checkRoomIsAvailableForEmployeeV2 = (
    room: Pick<Reservable, 'availabilityType' | 'ReservableToDepartments' | 'ReservableToEmployees'>,
    employee: Pick<TEmployee, 'id' | 'departmentIDs'>,
) => {
    switch (room.availabilityType) {
        case AvailabilityType.BOOKED_FOR_PERSON: {
            return (
                findOverlap(
                    (room.ReservableToEmployees?.items || [])
                        .filter((it) => !!it?.employee)
                        .map((it) => it!.employee!.id!) || [],
                    [employee.id] || [],
                ).length > 0
            )
        }
        case AvailabilityType.BOOKED_FOR_TEAM: {
            return (
                findOverlap(
                    (room.ReservableToDepartments?.items || [])
                        .filter((it) => !!it?.Department)
                        .map((it) => it!.Department!.id!) || [],
                    employee.departmentIDs || [],
                ).length > 0
            )
        }
        case AvailabilityType.UNAVALIABLE: {
            return false
        }
        default: {
            return true
        }
    }
}

export function arraysEqual(a: any[], b: any[]) {
    if (a.length !== b.length) {
        return false
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false
        }
    }
    return true
}

// Helper function to get formatted time text
export const getTimeText = (event) => {
    const startTime = moment.utc(event.start).format('HH:mm')
    const endTime = moment.utc(event.end).format('HH:mm')
    return `${startTime} - ${endTime}`
}

export const isPointInPolygon = (point: Point, points: number[]): boolean => {
    let inside = false

    if (!point.x || !point.y || !points.length) {
        return false
    }

    const n = points.length / 2 // Number of points in the polygon

    for (let i = 0, j = n - 1; i < n; j = i++) {
        const xi = points[i * 2],
            yi = points[i * 2 + 1]
        const xj = points[j * 2],
            yj = points[j * 2 + 1]

        const intersect = yi > point.y !== yj > point.y && point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi
        if (intersect) {
            inside = !inside
        }
    }
    return inside
}

export const mapTimeUnit = (unit: HorizonOfPlanningTimeUnit): unitOfTime.DurationConstructor => {
    switch (unit) {
        case HorizonOfPlanningTimeUnit.DAY:
            return 'day'
        case HorizonOfPlanningTimeUnit.WEEK:
            return 'week'
        case HorizonOfPlanningTimeUnit.MONTH:
            return 'month'
    }
}

export const createDateFilter = ({
    bookingRepeatType,
    fromDate,
    toDate,
    dates,
}: {
    bookingRepeatType?: string
    fromDate: string
    toDate: string
    dates: string[]
}) => {
    if (bookingRepeatType === RepeatType.WEEKLY) {
        return { between: [{ date: fromDate }, { date: toDate }] }
    } else if (dates.length === 1) {
        return { beginsWith: { date: fromDate } }
    }
    return undefined
}

export const createParkingFilter = ({
    bookingRepeatType,
    repeatDaysOfWeek,
    fromDate,
    toDate,
    startTime,
    endTime,
    dates,
}: {
    bookingRepeatType?: string
    repeatDaysOfWeek: string[] | null
    fromDate: string
    toDate: string
    startTime: string
    endTime: string
    dates: string[]
}) => {
    const timeFilter = {
        or: [
            {
                and: [{ startBookingTime: { lt: endTime } }, { endBookingTime: { gt: startTime } }],
            },
        ],
    }

    if (bookingRepeatType === 'WEEKLY') {
        return {
            and: [
                { date: { between: [fromDate, toDate] } },
                timeFilter,
                ...(repeatDaysOfWeek !== null
                    ? [
                          {
                              or: repeatDaysOfWeek.map((day) => ({
                                  dayOfWeek: {
                                      eq: day,
                                  },
                              })),
                          },
                      ]
                    : []),
            ],
        }
    } else if (bookingRepeatType === 'CUSTOM' && dates.length > 1) {
        return {
            and: [
                {
                    or: dates.map((date) => ({
                        date: { eq: date },
                    })),
                },
                timeFilter,
            ],
        }
    } else if (dates.length === 1) {
        return {
            and: [{ date: { eq: dates[0] } }, timeFilter],
        }
    }
    return undefined
}

/**
 * Generates an array of week days' short names based on company rules.
 *
 * @param {Object} company - The company object containing rules.
 * @param {boolean} [company.Rules.blockReservationOnNonWorkingDays=false] - Whether to exclude weekends.
 * @param {Array<string>} [company.Rules.workingDays=['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']] - Array of working days.
 * @returns {Array<string>} Array of short names of week days.
 */
export function generateWeekDaysArray(company: TCompany): string[] {
    // Extract rules from the company object with default values
    const dontShowWeekends = company?.Rules?.blockReservationOnNonWorkingDays || false
    const workingDays = company?.Rules?.workingDays || ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

    // Mapping of English day names to ISO weekday numbers
    const dayNameToIsoNumber = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
    }

    let weekDays

    if (dontShowWeekends) {
        // Map each working day to its ISO number and then format to short name
        weekDays = workingDays
            .map((day) => {
                const isoNumber = dayNameToIsoNumber[day!]
                if (!isoNumber) {
                    console.warn(`Invalid day name: ${day}. Skipping.`)
                    return null
                }
                return moment().isoWeekday(isoNumber).format('ddd')
            })
            .filter((day) => day !== null) // Remove any null entries due to invalid day names
    } else {
        // Get all short day names according to locale
        // Using ISO weekdays to ensure consistency (Monday = 1, Sunday = 7)
        weekDays = [...Array(7)].map((_, i) => {
            return moment()
                .isoWeekday(i + 1)
                .format('ddd')
        })
    }

    return weekDays
}
