import React, { useMemo, memo, CSSProperties } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import LogInScreenLink from 'components/atoms/LogInScreenLink'

import { Footer, FooterAmpersand, FooterLinks, FooterSignature } from './styles'

interface AuthFooterProps {
    style?: CSSProperties
}

const AuthFooter = ({ style }: AuthFooterProps) => {
    const { t } = useTranslation(['translation'])
    const currentYear = useMemo(() => new Date().getFullYear(), [])

    return (
        <Footer style={style}>
            <FooterSignature>
                &#169; {currentYear} Yoffix. {t('All rights reserved')}
            </FooterSignature>
            <FooterLinks>
                <Link to="https://yoffix.com/privacy-policy" target="_blank">
                    <LogInScreenLink size={12}>{t('Privacy Policy')}</LogInScreenLink>
                </Link>
                <FooterAmpersand>&</FooterAmpersand>
                <Link to="https://yoffix.com/terms" target="_blank">
                    <LogInScreenLink size={12}>{t('Terms')}</LogInScreenLink>
                </Link>
            </FooterLinks>
        </Footer>
    )
}

export default memo(AuthFooter)
