import React, { FC, useCallback, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import ContainerLoader from 'components/atoms/ContainerLoader'
import { EmployeeItemWithForwardedRef } from 'components/atoms/EmployeeItem'
import Filters from 'components/widgets/Filters'
import { useEmployeesData } from 'containers/Employees/hooks/useEmployeesData'
import { EmployeeListItem } from 'containers/Employees/types'
import { noop } from 'helpers'

import CounterEmployees from './components/CounterEmployees'
import * as S from './styles'
import { useLoadBookings } from '../../../containers/SpaceScreen/pages/SpaceScreenBooking/hooks/useLoadBookings'
import { EmployeeData } from '../../../containers/SpaceScreen/types'
import { useAuth } from '../../../context'
import { EmployeeItem } from '../index'

type EmployeeListProps = {
    employee?: EmployeeData
    companyID: string
    date: string
    isTeamLead?: boolean
    onSelectEmployee?: (employee: EmployeeData) => void
    employeeList?: Array<EmployeeData>
    onMultiSelectEmployee?: (employee: Array<EmployeeData>) => void
    changeList?: (employees: Array<EmployeeListItem>) => void
    isMultiSelect?: boolean
    isMax?: boolean
    showMe?: boolean
    height?: string
    departmentIDs?: Array<string>
}
const EmployeeList: FC<EmployeeListProps> = ({
    employee: employeeInBooking,
    companyID,
    onSelectEmployee = noop,
    changeList = noop,
    onMultiSelectEmployee = noop,
    employeeList = [],
    isMultiSelect = false,
    isMax = false,
    height = '230px',
    showMe,
}) => {
    const { t } = useTranslation('translation')
    const observer = useRef<null | IntersectionObserver>(null)
    const rootRef = useRef<null | HTMLDivElement>(null)
    const lastRowRef = useRef<null | HTMLLIElement>(null)
    const { organizer } = useLoadBookings({
        companyID,
    })

    const { userAuth } = useAuth()

    const { employees, nextToken, hasMore, searchEmployees, searchByFilters, loading } = useEmployeesData({
        companyID,
    })

    const isSelected = useCallback(
        (id: string) => {
            if (isMultiSelect) {
                return employeeList.some((item) => {
                    return item.id === id
                })
            } else {
                return employeeInBooking && employeeInBooking.id === id
            }
        },
        [isMultiSelect, employeeList, employeeInBooking],
    )

    const handleSelect = useCallback(
        (employee: EmployeeListItem) => {
            const employeeData = {
                id: employee.id,
                fullName: employee.fullName,
                firstname: employee.firstname,
                lastname: employee.lastname,
                photo: employee.photo || '',
                departmentIDs: employee.departmentIDs || [],
                Position: employee.Position || undefined,
            }

            if (isMultiSelect) {
                !isSelected(employee.id)
                    ? onMultiSelectEmployee([...employeeList, employeeData])
                    : onMultiSelectEmployee(employeeList.filter((item) => item.id !== employee.id))
            } else {
                onSelectEmployee(employeeData)
            }
        },
        [employeeList, isMultiSelect, isSelected],
    )

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect()
        }

        observer.current = new IntersectionObserver(handleObserver, {
            root: rootRef.current,
            threshold: 0,
        })

        if (lastRowRef.current) {
            observer.current.observe(lastRowRef.current)
        }

        async function handleObserver([entity]: Array<IntersectionObserverEntry>) {
            if (lastRowRef.current === null && observer.current) {
                observer.current.disconnect()
                return
            }

            if (!entity.isIntersecting || !hasMore) {
                return
            }

            if (nextToken && searchEmployees) {
                await searchEmployees(nextToken)
            }

            return
        }

        return () => {
            if (lastRowRef.current !== null && observer.current) {
                observer.current.unobserve(lastRowRef.current)
            }
        }
    }, [nextToken, hasMore])

    useEffect(() => {
        changeList(employees)
    }, [employees])

    return (
        <>
            {organizer && showMe && isMultiSelect && (
                <S.EmployeeMe>
                    <EmployeeItem
                        emplName={t('Me')}
                        emplPhoto={organizer.photo || null}
                        emplDepartment={organizer.Position?.name}
                        avatarSize={36}
                        variant="div"
                        isSelected={isSelected(organizer.id)}
                        onSelect={() => handleSelect(organizer as EmployeeListItem)}
                    />
                </S.EmployeeMe>
            )}

            <S.FiltersWrapper>
                <S.Filters>
                    <Filters onChange={searchByFilters} disableAlienTeams />
                </S.Filters>
            </S.FiltersWrapper>

            {isMultiSelect && employeeList?.length > 0 && (
                <div className={'ml-8 mr-8 mt-5'}>
                    <CounterEmployees counter={employeeList?.length} title={'selected'}></CounterEmployees>
                </div>
            )}

            <S.EmployeeList ref={rootRef}>
                <SimpleBar style={{ height }} autoHide={false} className="timelines-scroll custom-vertical-scroll">
                    <ul>
                        {employees.map((employee, currentIndex) => {
                            const isLastRow = currentIndex === employees.length - 1

                            if (!showMe && userAuth && userAuth.employeeId === employee.id) {
                                return null
                            }

                            const selected = isSelected(employee.id)

                            return (
                                <>
                                    <EmployeeItemWithForwardedRef
                                        key={employee.id}
                                        emplName={employee.fullName}
                                        emplPhoto={employee.photo ? employee.photo : null}
                                        emplDepartment={employee.Position?.name}
                                        avatarSize={36}
                                        variant="div"
                                        ref={hasMore && isLastRow ? lastRowRef : null}
                                        isSelected={selected}
                                        onSelect={() => handleSelect(employee)}
                                        disabled={!selected && isMax}
                                    />
                                </>
                            )
                        })}
                    </ul>
                </SimpleBar>

                {loading && (
                    <S.LoaderWrap>
                        <ContainerLoader />
                    </S.LoaderWrap>
                )}
            </S.EmployeeList>
        </>
    )
}

export default EmployeeList
