import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FilterObject, FiltersTypes } from '../types'

export type Filters = {
    [FiltersTypes.OFFICES]?: FilterObject
    [FiltersTypes.TEAMS]?: FilterObject
    [FiltersTypes.STATUSES]?: FilterObject
}

type EmployeeFilters = {
    loaded?: boolean
    filters: Filters | null
}

const initialState: EmployeeFilters = {
    loaded: false,
    filters: null,
}

export const employeeFiltersSlice = createSlice({
    name: 'employeeFilters',
    initialState,
    reducers: {
        setFilters(state, action: PayloadAction<Filters>) {
            state.filters = action.payload
        },
        updateFilter(state, action: PayloadAction<{ type: keyof Filters; value: FilterObject }>) {
            const { type, value } = action.payload
            if (!state.filters) {
                state.filters = {
                    [type]: value,
                }
            } else {
                state.filters[type] = value
            }
        },
    },
})

export const employeeFiltersActions = employeeFiltersSlice.actions
export default employeeFiltersSlice.reducer
