import { gql } from '@apollo/client'

import { BOOKING } from 'graphql/dataQuery'

export const EMPLOYEE_INFO = gql`
    fragment EmployeeInfo on Employee {
        id
        firstname
        lastname
        fullName
        photo
        departmentIDs
        shareAttendance
        Position {
            name
        }
    }
`
export const GET_BOOKING_BY_RESERVABLE_ID = gql`
    ${EMPLOYEE_INFO}
    query GetSpaceAndReservable(
        $reservableID: ID!
        $employeeID: ID!
        $startDate: String!
        $dopDate: AWSDate!
        $spaceID: ID!
    ) {
        getSpace(id: $spaceID) {
            id
            availableParkingSlots(date: $dopDate, employeeID: $employeeID)
        }
        getReservable(id: $reservableID) {
            type
            availabilityType
            ReservableToDepartments {
                items {
                    Department {
                        id
                        name
                    }
                }
            }

            Employee {
                id
            }

            bookingReservables(startTime: { beginsWith: $startDate }, limit: 1000) {
                items {
                    id
                    booking {
                        id
                        startTime
                        endTime
                        bookingRequestID
                        Employee {
                            ...EmployeeInfo
                        }
                        BookingRequest {
                            id
                            addParkingSlot
                        }
                        isBlocked
                    }
                }
            }

            Bookings(startTime: { beginsWith: $startDate }, limit: 1000) {
                items {
                    id
                    startTime
                    endTime
                    bookingRequestID
                    Employee {
                        ...EmployeeInfo
                    }
                    BookingRequest {
                        id
                        addParkingSlot
                    }
                    isBlocked
                    BookingRequest {
                        ParkingSlot {
                            id
                            name
                            y
                            x
                            employeeID
                            Space {
                                id
                                name
                                address
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_ROOM_EVENTS_FOR_MANY_DAYS_BY_RESERVABLE_ID = gql`
    ${EMPLOYEE_INFO}
    query ListCalendarEventssByID($dateFilter: ModelCalendarEventsFilterInput, $reservableID: ID!) {
        getReservable(id: $reservableID) {
            type
            availabilityType
            ReservableToDepartments {
                items {
                    Department {
                        id
                        name
                    }
                }
            }

            Employee {
                id
            }

            maxAmountOfPeople
            externalRoomID
        }
        listCalendarEventsByReservable(reservableID: $reservableID, filter: $dateFilter, limit: 1000) {
            items {
                id
                startTime
                endTime
                organiserEmployeeID
                title
                description
                isOnlineMeeting
                onlineMeetingUrl
                isPrivate
                employeesIDs
                CalendarEventRequest {
                    id
                    repeatType
                }
                OrganiserEmployee {
                    ...EmployeeInfo
                }
                Employees {
                    items {
                        Employee {
                            ...EmployeeInfo
                        }
                    }
                }
                Catering {
                    id
                    title
                    enabled
                }
                menuItems {
                    id
                    quantity
                    title
                    description
                }
            }
        }
    }
`

export const GET_BOOKING_BY_ID = gql`
    ${BOOKING}
    query getBookingById($bookingID: ID!) {
        getBooking(id: $bookingID) {
            ...BookingFields
            id
            startTime
            endTime
            isFullDay
            isBlocked
            employeeID
            Employee {
                id
                email
                firstname
                lastname
                fullName
                photo
                officeID
                positionID
                departmentIDs
                shareAttendance
            }
        }
    }
`

export const MUTATION_BOOKING_CANCELATION = gql`
    mutation CreateBookingCancellation($input: CreateBookingCancellationInput!) {
        createBookingCancellation(input: $input) {
            id
        }
    }
`

export const MUTATION_PARKING_BOOKING_DELETE = gql`
    mutation DeleteBookingReservable($input: DeleteBookingReservableInput!) {
        deleteBookingReservable(input: $input) {
            id
            companyID
        }
    }
`

export const MUTATION_PARKING_BOOKING_UPDATE = gql`
    mutation UpdateBookingRequestForParking($input: UpdateBookingRequestInput!) {
        updateBookingRequest(input: $input) {
            id
        }
    }
`
