import styled from 'styled-components'

export const TabsWrapper = styled.div`
    .MuiTabs-root {
        border-radius: 14px; // Скругление углов
        box-shadow: none;
        width: 100%;
        min-width: 100%;
        min-height: 36px;
        border: 1px solid #e3e8ee;
        line-height: 1;
    }

    .MuiTabs-flexContainer {
    }

    .MuiTabs-indicator {
        background-color: transparent;
        transition: none;
        min-width: auto;
        display: none;
        transition-duration: 0s !important;
        left: 0 !important;
        width: auto !important;
    }
    .MuiTab-root {
        min-width: 50%;
        text-transform: none;
        padding: 9px 12px;
        font-size: 12px;
        opacity: 1;
        min-height: auto;
        border-radius: 14px;
        border: 1px solid #fff;
    }

    .MuiTab-root.Mui-selected {
        background: var(--main_black, #333); // Фон для активного таба
        color: #fff;
        transition: none;
    }

    .MuiTab-wrapper {
        font-size: 12px;
    }
    .MuiTab-root:hover {
        transition: none;
    }
`
