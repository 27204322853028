import React, { FC, useMemo } from 'react'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SettingsSVG } from 'assets/common'
import { IconIntercom24SVG } from 'assets/icons'
import { LogoutSVG, YoffoxSVG } from 'assets/navigation'
import { UserAvatar } from 'components/atoms'
import TooltipContainer from 'components/atoms/TooltipContainer'
import { useAuth } from 'context'
import { getAccessibleSidebarLinks } from 'helpers/permissions'
import { useAppSelector } from 'hooks'
import { ROUTES } from 'types/routes'
import { EUserGroup } from 'types/user.types'

import NavbarLinks from './NavbarLinks'
import { employeeSidebarLinks, teamLeadSidebarLinks, sidebarLinks, sidebarResourceMap } from '../const'
import * as S from '../styles'
import { SidebarLinks } from '../types'

type DesktopSidebarProps = {
    pathname: string
}

const DesktopSidebar: FC<DesktopSidebarProps> = ({ pathname }) => {
    const { t } = useTranslation('translation')
    const { roomManagement, useOnlySpaceScreen, turnOffRooms, turnOffSpaceScreen, resources } = useFlags()
    const { signOut, userGroup } = useAuth()

    const permissions = useAppSelector(({ user }) => user.permissions)
    const userName = useAppSelector(({ user }) => user.fullName)

    // Select base sidebar links based on user group
    const groupLinksMap: Record<string, SidebarLinks> = {
        [EUserGroup.EMPLOYEE]: employeeSidebarLinks,
        [EUserGroup.TEAM_LEAD]: teamLeadSidebarLinks,
    }
    const baseLinks = groupLinksMap[userGroup] || sidebarLinks

    const accessibleLinks = useMemo(() => {
        return getAccessibleSidebarLinks(baseLinks, sidebarResourceMap, permissions || [])
    }, [baseLinks, permissions])

    const finalLinks = useMemo(() => {
        const linksCopy = { ...accessibleLinks }
        if (roomManagement) {
            linksCopy.calendar = null
        }
        if (useOnlySpaceScreen) {
            linksCopy.rooms = null
            linksCopy.calendar = null
        }
        if (turnOffRooms) {
            linksCopy.rooms = null
        }
        if (turnOffSpaceScreen) {
            linksCopy.spaces = null
        }
        if (!resources) {
            linksCopy.resources = null
        }
        return linksCopy
    }, [accessibleLinks, roomManagement, useOnlySpaceScreen, turnOffRooms, turnOffSpaceScreen, resources])

    const handleSingOut = async () => {
        await signOut()
    }

    return (
        <S.SidebarRoot>
            <S.LogoWrapper>
                <YoffoxSVG />
            </S.LogoWrapper>
            <NavbarLinks pathname={pathname} links={finalLinks} />
            <S.SidebarList className="bottom-navigation">
                <S.SidebarListItem id="intercom-launcher" className="flex-center">
                    <IconIntercom24SVG fill="#ffffff" fillOpacity={0.3} />
                </S.SidebarListItem>
                <TooltipContainer key="Profile" title={userName} {...S.TooltipProps}>
                    <Link to={ROUTES.PROFILE} data-intercom-target="Profile" role="button">
                        <S.ProfileAvatarWrap isActive={false} className="flex-center">
                            <UserAvatar imgSize={40} />
                        </S.ProfileAvatarWrap>
                    </Link>
                </TooltipContainer>

                {userGroup === EUserGroup.MANAGER && (
                    <>
                        <TooltipContainer key="Settings" title={t('Settings') as string} {...S.TooltipProps}>
                            <Link to={ROUTES.SETTINGS} data-intercom-target="Settings" role="button">
                                <S.SidebarListItem isActive={pathname.includes('settings')} className="flex-center">
                                    <SettingsSVG fill="#ffffff" fillOpacity={0.3} />
                                </S.SidebarListItem>
                            </Link>
                        </TooltipContainer>
                    </>
                )}
                <TooltipContainer key="Log out" title={t('Log out') as string} {...S.TooltipProps}>
                    <S.SidebarListItem onClick={handleSingOut} className="flex-center">
                        <LogoutSVG />
                    </S.SidebarListItem>
                </TooltipContainer>
            </S.SidebarList>
        </S.SidebarRoot>
    )
}

export default DesktopSidebar
