import { Dispatch, SetStateAction } from 'react'

import { RepeatType } from 'graphql/autogenerate/schemas'

import { PaddleType } from './paddle'

export type TImageSlider = {
    url: string
    key: string
}

export interface Point {
    x?: number | null
    y?: number | null
}

export type NextToken = string | null

export type MobileScheduleRepeatType = RepeatType

export type TPhotoLoaded = {
    id?: string
    src: string
    file: File | null
    fileName?: string
    originalFileName?: string
    isMain?: boolean
    isLocal?: boolean
}

export type TAddressGoogle = {
    city?: string
    country?: string
    address?: string
    zipcode?: string
    lat?: number
    long?: string
    str?: string
}

export type TConfirmPopup = {
    open: boolean
    type: 'delete' | 'create' | 'update' | 'deleteAll' | null
    id: string | string[] | null
    name: string
}

export type TConfirmPopupSpaceEditor = {
    open: boolean
    count?: number | string
    typeOfPopup?: 'deleteAll' | 'deleteReservable' | 'deleteAllTags' | null
    id?: string | string[] | null
    name?: string
}

declare global {
    interface Window {
        Paddle: PaddleType
        dataLayer?: any
        nativeInterface?: any
    }
}

export type DatePaginationBtnsType = 'prev' | 'next'

export type StateSetter<T> = Dispatch<SetStateAction<T>>

type PropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never
export type InferActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesTypes<T>>

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

export type MultiselectItem = string | { id: string; name: string }

export type MultiselectObjectItem = { id: string; name: string }

export enum BookingType {
    OFFICE = 'OFFICE',
    REMOTE = 'REMOTE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    TEAM_EVENT = 'TEAM_EVENT',
    ROOM = 'ROOM',
    APPROVED = 'APPROVED',
}

export enum BookingSubType {
    VACATION = 'VACATION',
    SICK_LEAVE = 'SICK_LEAVE',
    BUSINESS_TRIP = 'BUSINESS_TRIP',
}
