import { MultiselectItem } from 'types/common.types'

export type FilterObject = { items?: Array<MultiselectItem> | null; isAll?: boolean }

export enum FiltersTypes {
    OFFICES = 'offices',
    TEAMS = 'teams',
    STATUSES = 'statuses',
}

export type Filters = {
    [FiltersTypes.OFFICES]: FilterObject
    [FiltersTypes.TEAMS]: FilterObject
    [FiltersTypes.STATUSES]: FilterObject
}

export const LS_FILTERS_KEY = 'SAVED_FILTERS_FOR_EMPLOYEE_LIST'

export const initialFilters = {
    [FiltersTypes.OFFICES]: { items: null, isAll: true },
    [FiltersTypes.TEAMS]: { items: null, isAll: true },
    [FiltersTypes.STATUSES]: { items: null, isAll: true },
}
