import moment from 'moment/moment'

import {
    convertTimeByIndex,
    convertTimelineDateToIndexes,
    getFreeTimelines,
    getRoundedRoomHours,
    getTimeByPosition,
    getTimelineStyles,
    isDifferentDate,
} from 'helpers/timelineHelpers'
import { findOverlap, getHoursFromISODateString } from 'helpers/utils'

import { ERoleEmployee } from '../../../../types/data.types'
import {
    BookingEventTypeMode,
    BookingType,
    EmployeeData,
    ExtendedBookingType,
    ExtendedRoomEventType,
    FreeTimeline,
    RoomEventType,
} from '../../types'

const timelineHeight = 32

export { convertTimeByIndex, getRoundedRoomHours, getTimeByPosition, timelineHeight, getTimelineStyles }

/**
 * set timeline position values
 * **/
export const setTimelineValues = (height: number, start: number) => ({
    currentStart: start,
    currentHeight: height,
    oldStart: start,
    oldHeight: height,
})

/**
 * convert time to minutes
 * @param timeInHour
 */
export const convertH2M = (timeInHour) => {
    const timeParts = timeInHour.split(':')
    return Number(timeParts[0]) * 60 + Number(timeParts[1])
}

export const mapRoomEvents = (
    roomEvents: Array<RoomEventType>,
    selectedRoomEvent,
    startHour: number,
    timelinesAmount: number,
    organizer: EmployeeData,
    isManager: boolean,
    date: string,
): [Array<ExtendedRoomEventType>, Array<FreeTimeline>] => {
    const roomBookings = roomEvents.map((roomBooking) => {
        const isBookingForManyDays = isDifferentDate(roomBooking.startTime, roomBooking.endTime)
        const start = convertTimelineDateToIndexes(getHoursFromISODateString(roomBooking.startTime), startHour)
        const time = isBookingForManyDays
            ? `${getHoursFromISODateString(roomBooking.startTime)} - ${getHoursFromISODateString(
                  roomBooking.endTime,
              )} (${moment(roomBooking.endTime).format('DD MMM')})`
            : `${getHoursFromISODateString(roomBooking.startTime)} - ${getHoursFromISODateString(roomBooking.endTime)}`

        const canEdit = isManager || organizer.id === roomBooking.organiserEmployeeID
        return {
            ...roomBooking,
            start,
            height: isBookingForManyDays
                ? timelinesAmount - start
                : convertTimelineDateToIndexes(getHoursFromISODateString(roomBooking.endTime), startHour) - start,
            time,
            canEdit,
        }
    })

    if (selectedRoomEvent === null) {
        return [roomBookings, getFreeTimelines(timelinesAmount, roomBookings)]
    }

    const start = convertTimelineDateToIndexes(getHoursFromISODateString(selectedRoomEvent.startTime), startHour)
    const height =
        convertTimelineDateToIndexes(
            isDifferentDate(selectedRoomEvent.startTime, selectedRoomEvent.endTime)
                ? '23:59'
                : getHoursFromISODateString(selectedRoomEvent.endTime),
            startHour,
        ) - start

    if (selectedRoomEvent.mode === BookingEventTypeMode.CREATE && selectedRoomEvent.date === date) {
        const currentRoomBookings = [
            ...roomBookings,
            {
                id: selectedRoomEvent.id,
                start,
                height,
                time: `${getHoursFromISODateString(selectedRoomEvent.startTime)} - ${getHoursFromISODateString(
                    selectedRoomEvent.endTime,
                )}`,
                isNewEvent: true,
                canEdit: true,
                title: selectedRoomEvent.title,
                onlineMeetingUrl: selectedRoomEvent.onlineMeetingUrl,
                isOnlineMeeting: selectedRoomEvent.isOnlineMeeting,
                isPrivate: selectedRoomEvent.isPrivate,
                description: selectedRoomEvent.description,
                Employees: selectedRoomEvent.Employees,
                Reservable: selectedRoomEvent.Reservable,
                organiserEmployeeID: organizer.id,
                OrganiserEmployee: organizer,
            },
        ].sort((a, b) => a.start - b.start)

        return [currentRoomBookings, getFreeTimelines(timelinesAmount, currentRoomBookings)]
    }

    const currentRoomBookings = roomBookings
        .map((booking) =>
            booking.id === selectedRoomEvent.id && selectedRoomEvent.date === date
                ? {
                      ...booking,
                      start,
                      height,
                      title: selectedRoomEvent.title || booking.title,
                      time: selectedRoomEvent.time || booking.time,
                  }
                : booking,
        )
        .sort((a, b) => a.start - b.start)

    return [currentRoomBookings, getFreeTimelines(timelinesAmount, roomBookings, selectedRoomEvent.id)]
}

export const mapBookings = ({
    bookings,
    selectedBooking,
    startHour,
    timelinesAmount,
    organizer,
    date,
}: {
    bookings: Array<BookingType>
    selectedBooking
    startHour: number
    timelinesAmount: number
    organizer: EmployeeData
    organizerDepartmentIDs?: Array<string>
    date: string
}): [Array<ExtendedBookingType>, Array<FreeTimeline>] => {
    const loadedBookings = bookings.map((booking) => {
        const start = convertTimelineDateToIndexes(getHoursFromISODateString(booking.startTime), startHour)
        const time = `${getHoursFromISODateString(booking.startTime)} - ${getHoursFromISODateString(booking.endTime)}`
        const canEdit =
            [ERoleEmployee.MANAGER, ERoleEmployee.OWNER].includes(organizer.role as ERoleEmployee) ||
            organizer.id === booking.Employee.id ||
            (organizer.role === ERoleEmployee.TEAM_LEAD &&
                !!findOverlap(organizer.departmentIDs, booking.Employee.departmentIDs).length)

        return {
            ...booking,
            title: '',
            start,
            height: convertTimelineDateToIndexes(getHoursFromISODateString(booking.endTime), startHour) - start,
            time,
            canEdit,
        }
    })

    if (selectedBooking === null) {
        return [loadedBookings, getFreeTimelines(timelinesAmount, loadedBookings)]
    }

    const start = convertTimelineDateToIndexes(getHoursFromISODateString(selectedBooking.startTime), startHour)
    const height = convertTimelineDateToIndexes(getHoursFromISODateString(selectedBooking.endTime), startHour) - start

    if (selectedBooking.mode === BookingEventTypeMode.CREATE && selectedBooking.date === date) {
        const currentBookings = [
            ...loadedBookings,
            {
                id: selectedBooking.id,
                start,
                height,
                time: `${getHoursFromISODateString(selectedBooking.startTime)} - ${getHoursFromISODateString(
                    selectedBooking.endTime,
                )}`,
                isNewEvent: true,
                canEdit: true,
                title: selectedBooking.title,
                Employee: organizer,
            },
        ].sort((a, b) => a.start - b.start)

        return [currentBookings, getFreeTimelines(timelinesAmount, currentBookings)]
    }

    const currentRoomBookings = loadedBookings
        .map((booking) =>
            booking.id === selectedBooking.id && selectedBooking.date === date
                ? {
                      ...booking,
                      start,
                      height,
                      title: selectedBooking.title || booking.title,
                      time: selectedBooking.time || booking.time,
                  }
                : booking,
        )
        .sort((a, b) => a.start - b.start)

    return [currentRoomBookings, getFreeTimelines(timelinesAmount, loadedBookings, selectedBooking.id)]
}

/**
 * get free time slot for new booking
 * @param startTime
 * @param endTime
 * @param bookings
 * @param date
 * @param timeZone
 */
export const getSuggestedTimeSlot = (
    startTime: string, // start of the interval (e.g. 08:00)
    endTime: string, // end of the interval (e.g. 19:00)
    bookings: Array<BookingType>,
    date: string,
    timeZone: string,
): null | { startTime: string; endTime: string } => {
    const currentUsersTime = moment.tz(timeZone || 'Europe/Berlin')
    const format = 'YYYY-MM-DDTHH:mm:ss'
    let maxDuration = 0
    let maxSlotStart
    let currentTime = startTime
    let busySlotIndex = 0

    if (moment(date).isSame(currentUsersTime, 'day')) {
        const parsedStartTime = moment.tz(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm', timeZone || 'Europe/Berlin')
        currentTime = currentUsersTime.startOf('hour').format('HH:mm')

        if (currentUsersTime.isBefore(parsedStartTime)) {
            currentTime = startTime
        } else {
            currentTime = currentUsersTime.startOf('hour').format('HH:mm')
        }
    }

    while (convertH2M(currentTime) < convertH2M(endTime)) {
        if (
            busySlotIndex < bookings.length &&
            currentTime === getHoursFromISODateString(bookings[busySlotIndex].startTime)
        ) {
            currentTime = getHoursFromISODateString(bookings[busySlotIndex].endTime)
            busySlotIndex++
        } else {
            const freeSlotEnd =
                busySlotIndex < bookings.length ? getHoursFromISODateString(bookings[busySlotIndex].startTime) : endTime
            const freeSlotDuration = (convertH2M(freeSlotEnd) - convertH2M(currentTime)) / 60
            if (freeSlotDuration > maxDuration) {
                maxDuration = freeSlotDuration
                maxSlotStart = currentTime
            }
            currentTime = freeSlotEnd
        }
    }

    if (maxDuration === 0) {
        return null
    }

    const startTimeToISO = `${date}T${maxSlotStart}:00`

    return {
        startTime: startTimeToISO,
        endTime: moment(startTimeToISO).add(maxDuration, 'hours').format(format),
    }
}
