import styled from 'styled-components'

import { TTheme } from '../../../types/Theme'
export const Loader = styled.div<{ size: number; theme: TTheme }>`
    position: relative;
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};

    .MuiCircularProgress-root {
        width: 100% !important;
        height: 100% !important;
        color: ${(props) => {
            switch (props.theme) {
                case 'primary':
                    return '#3f51b5' // Синий для темы primary
                case 'secondary':
                    return '#f50057' // Розовый для темы secondary
                case 'dark':
                    return '#303030' // Темно-серый для темы dark
                case 'light':
                    return '#f3f3f3' // Светло-серый для темы light
                default:
                    return '#000000' // Цвет по умолчанию
            }
        }};
        svg {
            margin-left: 0;
            width: 100%;
            height: 100%;
        }
    }
`
