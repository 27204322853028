import {
    SUBSCRIPTION_CREATE_BOOKING_BY_RESERVABLE,
    SUBSCRIPTION_DELETE_BOOKING_BY_RESERVABLE,
    SUBSCRIPTION_UPDATE_BOOKING_BY_RESERVABLE,
} from 'graphql/subscriptions'
import { useReconnectingSubscription } from 'helpers/useReconnectingSubscription'

import { SUBSCRIPTION_DELETE_PARKING } from '../../../components/SpaceScreenMap/graphql'

export const useSubscriptionsCreateSeatData = (
    companyID: string,
    spaceID: string | null,
    reservableID: string | null,
    update: (event: any) => void,
) => {
    useReconnectingSubscription<{ onCreateBookingByReservable }, { companyID: string; reservableID: string | null }>(
        SUBSCRIPTION_CREATE_BOOKING_BY_RESERVABLE,
        {
            skip: !reservableID,
            variables: { companyID, reservableID },
            onData: async ({ data }) => {
                update(data.data?.onCreateBookingByReservable)
            },
        },
    )
}

export const useSubscriptionsDeleteSeatData = (
    companyID: string,
    spaceID: string | null,
    reservableID: string | null,
    update: (event: any) => void,
) => {
    useReconnectingSubscription<{ onDeleteBookingByReservable }, { companyID: string; reservableID: string | null }>(
        SUBSCRIPTION_DELETE_BOOKING_BY_RESERVABLE,
        {
            variables: { companyID, reservableID },
            skip: !reservableID,
            onData: async ({ data }) => {
                update(data.data?.onDeleteBookingByReservable)
            },
        },
    )
}

export const useSubscriptionsUpdateSeatData = (
    companyID: string,
    spaceID: string | null,
    reservableID: string | null,
    update: (event: any) => void,
) => {
    useReconnectingSubscription<{ onUpdateBookingByReservable }, { companyID: string; reservableID: string | null }>(
        SUBSCRIPTION_UPDATE_BOOKING_BY_RESERVABLE,
        {
            variables: { companyID, reservableID },
            skip: !reservableID,
            onData: async ({ data }) => {
                console.log('onUpdateBookingByReservable')
                update(data.data?.onUpdateBookingByReservable)
            },
        },
    )
}

const useSubscriptionsSeatData = (
    companyID: string,
    spaceID: string | null,
    reservableID: string | null,
    update: () => void,
) => {
    useReconnectingSubscription<{ onDeleteBookingReservableByCompany }, { companyID: string }>(
        SUBSCRIPTION_DELETE_PARKING,
        {
            variables: { companyID },
            skip: !spaceID,
            onData: async () => {
                update()
            },
        },
    )
}

export default useSubscriptionsSeatData
