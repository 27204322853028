import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

import { ReservableSpaceScreen } from '../../../components/organisms/Reservable'
import { getAWSFormatDate, getConvertedEngLocaleDate } from '../../../helpers'
import { TTag } from '../../../types'
import { MainEmployeeData } from '../../../types/data.types'
import { MobileScheduleRepeatType } from '../../Calendar/types'
import {
    BookingEventTypeMode,
    BookingsByDay,
    EventTypes,
    RoomEventsByDay,
    SelectedBookingType,
    SelectedRoomEventType,
} from '../types'

import type { Employee, Space } from 'graphql/autogenerate/schemas'

type SpaceScreenState = {
    isFilterChanged: boolean
    isBookingOpened: boolean
    isBookingLoading: boolean
    isBookingError: boolean
    bookingScreenType: EventTypes | null
    bookings: BookingsByDay
    roomEvents: RoomEventsByDay
    selectedBooking: null | SelectedBookingType
    selectedRoomEvent: null | SelectedRoomEventType
    selectedSpaceID: null | string
    selectedOfficeID: null | string
    startSpaceWorkingTime: string
    endSpaceWorkingTime: string
    floorPlanFilePath: string | null
    date: Array<string>
    startFilterTime: string
    endFilterTime: string
    selectedReservableID: null | string
    findReservableID: null | string
    suggestedSeatID: string | null
    suggestedSeat: ReservableSpaceScreen | null
    suggestedParkingSpot: ReservableSpaceScreen | null
    isSpaceMapLoading: boolean
    isChangeDateTimeLoading: boolean
    employeesFromSpace: Array<MainEmployeeData>
    searchEmployeeID: string | null
    selectedEmployee: Employee | null
    isBackdrop: boolean
    isTVMode: boolean
    loading: boolean
    timeZone: string
    bookingRepeatType: MobileScheduleRepeatType
    repeatDaysOfWeek: Array<string> | null
    parkingMapStatus: boolean
    currentSelectSpace: Space | null
    selectParkingReservable: ReservableSpaceScreen | null
    parkingReservables: ReservableSpaceScreen[]
    selectedSpaceReservationID: null | string
    selectedReservableSpaceID: null | string
    weekDays: string[]
    tagsFilter: {
        loader: boolean
        items: TTag[]
    }
    filter: {
        bookingRepeatType?: MobileScheduleRepeatType
        repeatDaysOfWeek: Array<string>
        repeatDate: Array<string>
        selectTags: TTag[]
        show: boolean
    }
    widget: {
        countOfSeats: number
        countOfAvailableSeats: number
        countOfZone: number
        countOfAvailableZone: number
        countOfRooms: number
        countOfAvailableRooms: number
        countOfParking: number
        countOfAvailableParking: number
        countOfPeople: number
        countOfPeopleInOffice: number
    }
    repeatDate: Array<string>
}

export const initialState: SpaceScreenState = {
    isBookingOpened: false,
    isBookingLoading: false,
    isBookingError: false,
    bookingScreenType: null,
    bookings: {},
    roomEvents: {},
    selectedBooking: null,
    selectedRoomEvent: null,
    selectedSpaceID: null,
    selectedOfficeID: null,
    startSpaceWorkingTime: '00:00',
    endSpaceWorkingTime: '23:59',
    floorPlanFilePath: null,
    date: [getAWSFormatDate(moment())],
    startFilterTime: '00:00',
    endFilterTime: '23:59',
    selectedReservableID: null,
    findReservableID: null,
    suggestedSeatID: null,
    suggestedSeat: null,
    suggestedParkingSpot: null,
    isSpaceMapLoading: false,
    isChangeDateTimeLoading: false,
    employeesFromSpace: [],
    searchEmployeeID: null,
    selectedEmployee: null,
    isBackdrop: false,
    isTVMode: false,
    isFilterChanged: false,
    loading: false,
    timeZone: 'Europe/Berlin',
    bookingRepeatType: MobileScheduleRepeatType.NEVER,
    repeatDaysOfWeek: null,
    parkingMapStatus: false,
    currentSelectSpace: null,
    selectParkingReservable: null,
    parkingReservables: [],
    selectedSpaceReservationID: null,
    selectedReservableSpaceID: null,
    weekDays: [],
    filter: {
        bookingRepeatType: MobileScheduleRepeatType.NEVER,
        repeatDaysOfWeek: [],
        repeatDate: [],
        selectTags: [],
        show: false,
    },
    tagsFilter: {
        loader: false,
        items: [],
    },
    widget: {
        countOfSeats: 0,
        countOfAvailableSeats: 0,
        countOfZone: 0,
        countOfAvailableZone: 0,
        countOfRooms: 0,
        countOfAvailableRooms: 0,
        countOfParking: 0,
        countOfAvailableParking: 0,
        countOfPeople: 0,
        countOfPeopleInOffice: 0,
    },
    repeatDate: [],
}

export const spaceScreenSlice = createSlice({
    name: 'spaceScreen',
    initialState: initialState,
    reducers: {
        openBooking: (state, action: PayloadAction<EventTypes>) => ({
            ...state,
            isBookingOpened: true,
            bookingScreenType: action.payload,
        }),
        closeBooking: (state) => ({
            ...state,
            isBookingOpened: false,
            bookingScreenType: null,
        }),
        addBooking: (state, action: PayloadAction<SelectedBookingType>) => ({
            ...state,
            selectedBooking: action.payload,
        }),
        addRoomEvent: (state, action: PayloadAction<SelectedRoomEventType | null>) => ({
            ...state,
            selectedRoomEvent: action.payload,
        }),
        setBookings: (state, action: PayloadAction<BookingsByDay>) => ({
            ...state,
            bookings: action.payload,
        }),
        setRoomEvents: (state, action: PayloadAction<RoomEventsByDay>) => ({
            ...state,
            roomEvents: action.payload,
        }),
        selectBooking: (state, action: PayloadAction<{ date: string; id: string }>) => {
            const currentBooking = state.bookings[action.payload.date].find(
                (booking) => booking.id === action.payload.id,
            )
            return {
                ...state,
                selectedBooking: currentBooking
                    ? {
                          ...currentBooking,
                          date: action.payload.date,
                          mode: BookingEventTypeMode.EDIT,
                      }
                    : null,
            }
        },
        selectRoomEvent: (state, action: PayloadAction<{ date: string; id: string }>) => {
            const currentBooking = state.roomEvents[action.payload.date].find((item) => item.id === action.payload.id)
            return {
                ...state,
                selectedRoomEvent: currentBooking
                    ? {
                          ...currentBooking,
                          date: action.payload.date,
                          mode: BookingEventTypeMode.EDIT,
                      }
                    : null,
            }
        },
        changeSelectedBooking: (state, action: PayloadAction<Partial<SelectedBookingType>>) => ({
            ...state,
            selectedBooking: state.selectedBooking !== null ? { ...state.selectedBooking, ...action.payload } : null,
        }),

        changeSelectedRoomEvent: (state, action: PayloadAction<Partial<SelectedRoomEventType>>) => ({
            ...state,
            selectedRoomEvent:
                state.selectedRoomEvent !== null ? { ...state.selectedRoomEvent, ...action.payload } : null,
        }),
        selectDate: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                date: action.payload,
                isFilterChanged: true,
            }
        },
        selectFilterTime: (state, action: PayloadAction<{ start: string; end: string }>) => {
            // sessionStorage.setItem(
            //     'filterTime',
            //     JSON.stringify({
            //         startFilterTime: action.payload.start,
            //         endFilterTime: action.payload.end,
            //     }),
            // )
            return {
                ...state,
                isFilterChanged: true,
                startFilterTime: action.payload.start,
                endFilterTime: action.payload.end,
            }
        },
        setSpaceMapLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSpaceMapLoading: action.payload,
            }
        },
        setIsChangeDateTimeLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isChangeDateTimeLoading: action.payload,
            }
        },

        selectSpace: (
            state,
            action: PayloadAction<{
                id: string
                start: string
                end: string
                plan: string | null
                officeID: string
                timeZone: string
            }>,
        ) => ({
            ...state,
            selectedSpaceID: action.payload.id,
            selectedOfficeID: action.payload.officeID,
            startSpaceWorkingTime: action.payload.start,
            endSpaceWorkingTime: action.payload.end,
            startFilterTime: action.payload.start,
            isFilterChanged: false,
            endFilterTime: action.payload.end,
            floorPlanFilePath: action.payload.plan,
            timeZone: action.payload.timeZone || 'Europe/Berlin',
        }),
        setCurrentSpace: (state, action: PayloadAction<Space | null>) => ({
            ...state,
            currentSelectSpace: action.payload,
        }),
        // setFilterSwitch: (state, action: PayloadAction) => ({
        //     ...state,
        //     endFilterTime: state.endSpaceWorkingTime,
        //     startFilterTime: state.startSpaceWorkingTime,
        // }),
        selectReservable: (state, action: PayloadAction<string>) => ({
            ...state,
            selectedReservableID: action.payload,
        }),
        setSuggestedSeatID: (state, action: PayloadAction<string>) => ({
            ...state,
            suggestedSeatID: action.payload,
        }),
        setFindReservable: (state, action: PayloadAction<string | null>) => ({
            ...state,
            findReservableID: action.payload,
        }),
        setSuggestedSeat: (state, action: PayloadAction<ReservableSpaceScreen | null>) => ({
            ...state,
            suggestedSeat: action.payload,
        }),
        setSuggestedParkingSpot: (state, action: PayloadAction<ReservableSpaceScreen | null>) => ({
            ...state,
            suggestedParkingSpot: action.payload,
        }),
        setSearchEmployeeID: (state, action: PayloadAction<string | null>) => ({
            ...state,
            searchEmployeeID: action.payload,
        }),
        setBackdrop: (state, action: PayloadAction<boolean>) => ({
            ...state,
            isBackdrop: action.payload,
        }),
        setIsTVMode: (state, action: PayloadAction<boolean>) => ({
            ...state,
            isTVMode: action.payload,
        }),
        setEmployeesFromSpace: (state, action: PayloadAction<Array<MainEmployeeData>>) => ({
            ...state,
            employeesFromSpace: action.payload,
        }),
        unselectBooking: (state) => {
            return {
                ...state,
                selectedBooking: null,
            }
        },
        setLoading: (state, action: PayloadAction<boolean>) => ({
            ...state,
            loading: action.payload,
        }),
        setOpenBookingErrorScreen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            isBookingError: action.payload,
        }),

        setRepeatDaysOfWeek: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDaysOfWeek: action.payload,
            }
        },

        setBookingRepeatType: (state, action: PayloadAction<MobileScheduleRepeatType>) => {
            return {
                ...state,
                bookingRepeatType: action.payload,
                repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(state.date[0]), 'dddd')],
            }
        },

        setParkingMapStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                parkingMapStatus: action.payload,
            }
        },

        setSelectParkingReservable: (state, action: PayloadAction<ReservableSpaceScreen>) => {
            return {
                ...state,
                selectParkingReservable: action.payload,
            }
        },

        setSelectParkingListReservable: (state, action: PayloadAction<ReservableSpaceScreen[]>) => {
            return {
                ...state,
                parkingReservables: action.payload,
            }
        },

        setSelectedSpaceReservationID: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                selectedSpaceReservationID: action.payload,
            }
        },

        setSelectedReservableSpaceID: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                selectedReservableSpaceID: action.payload,
            }
        },

        setRepeatDate: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDate: action.payload,
            }
        },

        setSelectedEmployee: (state, action: PayloadAction<Employee>) => {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        },

        setTagsFilter: (state, action: PayloadAction<{ loader?: boolean; items?: TTag[] }>) => {
            if (action.payload.loader) {
                state.tagsFilter.loader = action.payload.loader
            }

            if (action.payload.items) {
                state.tagsFilter.items = action.payload.items
            }
        },

        setTagsFilterLoader: (state, action: PayloadAction<{ loader: boolean }>) => {
            return {
                ...state,
                tagsFilter: {
                    ...state.tagsFilter,
                    loader: action.payload.loader,
                },
            }
        },

        setFilterShow: (state, action: PayloadAction<{ show: boolean }>) => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    show: action.payload.show,
                },
            }
        },

        setFilter: (
            state,
            action: PayloadAction<{
                bookingRepeatType?: MobileScheduleRepeatType
                repeatDaysOfWeek?: Array<string>
                repeatDate?: Array<string>
                selectTags?: TTag[]
            }>,
        ) => {
            let filterChange = true
            const changedFilter = {
                ...state.filter,
            }
            if (action.payload.bookingRepeatType) {
                changedFilter.bookingRepeatType = action.payload.bookingRepeatType
            }

            const repeatDaysOfWeekCurrent = [...state.filter.repeatDaysOfWeek]

            if (
                action.payload.bookingRepeatType === MobileScheduleRepeatType.WEEKLY &&
                repeatDaysOfWeekCurrent.length === 0
            ) {
                changedFilter.repeatDaysOfWeek = [getConvertedEngLocaleDate(moment(state.date[0]), 'dddd')]
            }

            if (action.payload.repeatDaysOfWeek) {
                changedFilter.repeatDaysOfWeek = action.payload.repeatDaysOfWeek
            }

            if (action.payload.repeatDate) {
                changedFilter.repeatDate = action.payload.repeatDate
            }
            if (action.payload.selectTags) {
                changedFilter.selectTags = action.payload.selectTags
                filterChange = false
            }

            return {
                ...state,
                isFilterChanged: filterChange,
                filter: changedFilter,
            }

            // sessionStorage.setItem('filter', JSON.stringify(state.filter))
        },

        setWidgetInform: (
            state,
            action: PayloadAction<{
                countOfSeats?: number
                countOfAvailableSeats?: number
                countOfZone?: number
                countOfAvailableZone?: number
                countOfRooms?: number
                countOfAvailableRooms?: number
                countOfParking?: number
                countOfAvailableParking?: number
                countOfPeople?: number
                countOfPeopleInOffice?: number
            }>,
        ): void => {
            if (action.payload.countOfSeats) {
                state.widget.countOfSeats = action.payload.countOfSeats
            }
            if (action.payload.countOfAvailableSeats) {
                state.widget.countOfAvailableSeats = action.payload.countOfAvailableSeats
            }
            if (action.payload.countOfZone) {
                state.widget.countOfZone = action.payload.countOfZone
            }
            if (action.payload.countOfAvailableZone) {
                state.widget.countOfAvailableZone = action.payload.countOfAvailableZone
            }
            if (action.payload.countOfRooms) {
                state.widget.countOfRooms = action.payload.countOfRooms
            }
            if (action.payload.countOfAvailableRooms) {
                state.widget.countOfAvailableRooms = action.payload.countOfAvailableRooms
            }
            if (action.payload.countOfParking) {
                state.widget.countOfParking = action.payload.countOfParking
            }
            if (action.payload.countOfAvailableParking) {
                state.widget.countOfAvailableParking = action.payload.countOfAvailableParking
            }
            if (action.payload.countOfPeople) {
                state.widget.countOfPeople = action.payload.countOfPeople
            }
            if (action.payload.countOfPeopleInOffice) {
                state.widget.countOfPeopleInOffice = action.payload.countOfPeopleInOffice
            }
        },

        setWeekDays: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                weekDays: action.payload,
            }
        },

        reset: (state) => {
            return {
                ...initialState,
                filter: state.filter,
                widget: state.widget,
                tagsFilter: state.tagsFilter,
                findReservableID: state.findReservableID,
                date: state.date,
                weekDays: state.weekDays,
                startFilterTime: state.startFilterTime,
                endFilterTime: state.endFilterTime,
                selectedSpaceID: state.selectedSpaceID,
                selectedOfficeID: state.selectedOfficeID,
                startSpaceWorkingTime: state.startSpaceWorkingTime,
                endSpaceWorkingTime: state.endSpaceWorkingTime,
                floorPlanFilePath: state.floorPlanFilePath,
                employeesFromSpace: state.employeesFromSpace,
                isBackdrop: state.isBackdrop,
                selectedEmployee: state.selectedEmployee,
            }
        },
    },
})

export const spaceScreenActions = spaceScreenSlice.actions
export default spaceScreenSlice.reducer
