import React, { FC, useRef, useState, useEffect, HTMLAttributes } from 'react'

import * as S from './styles'
import TooltipContainer from '../TooltipContainer'

type TProps = {
    text: string
    position?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
} & HTMLAttributes<HTMLDivElement>

const TextOverflow: FC<TProps> = ({ text, position = 'right', ...props }) => {
    const textRef = useRef<HTMLDivElement>(null)
    const [isOverflowing, setIsOverflowing] = useState(false)

    useEffect(() => {
        const checkOverflow = () => {
            const current = textRef.current
            if (current) {
                const isOverflow = current.offsetWidth < current.scrollWidth
                setIsOverflowing(isOverflow)
            }
        }

        checkOverflow()
        window.addEventListener('resize', checkOverflow)
        return () => window.removeEventListener('resize', checkOverflow)
    }, [text])

    return (
        <TooltipContainer
            disableHoverListener={!isOverflowing}
            title={text as string}
            placement={position}
            offset="0px, 8px"
            style={{
                zIndex: 999999999999999,
            }}
        >
            <S.TextOverflow {...props} ref={textRef} className={'df align-items-center'}>
                {text}
            </S.TextOverflow>
        </TooltipContainer>
    )
}

export default TextOverflow
