import moment from 'moment'

import { getSelectItemKey } from './utils'
import { MultiselectItem, NextToken } from '../types/common.types'
import { EmployeeStatus } from '../types/data.types'

type RequestCommonArgs = {
    weekNumber?: number
    startWeekDate?: string
    employeeId: string
    favouriteOfficeId?: string
    statuses?: Array<MultiselectItem>
}
export type DepartmentIdsFilter = {
    departmentIDsString: {
        wildcard: string
    }
}

export type FavouriteColleagueIDsFilter = {
    id: {
        eq: string
    }
}

export type OfficeIdsFilter = { favouriteOfficeID: { eq: string } }
export type FirstNameFilter = { and: Array<{ firstname: { wildcard: string } }> }
export type LastNameFilter = { and: Array<{ lastname: { wildcard: string } }> }
export type FullNameFilter = { and: Array<{ fullNameLowerCase: { wildcard: string } }> }
export type EmailFilter = { and: Array<{ emailForSearch: { wildcard: string } }> }

type StatusesFilter = {
    eq?: EmployeeStatus
    ne?: EmployeeStatus
}

export const LimitByDepartment = 5

export const statusesList = [
    {
        id: EmployeeStatus.REGISTRED,
        name: 'Registered',
    },
    {
        id: EmployeeStatus.UNREGISTRED,
        name: 'Unregistered',
    },
]

export const generateFilterForSearchEmployees = ({
    weekNumber,
    startWeekDate,
    employeeId,
    favouriteOfficeId,
    filterByNameAndEmail,
    departmentData,
    officeData,
    limit,
    nextToken,
    statuses,
    companyID,
    isManager,
    date,
    noStatusesFilter,
    myFavouriteColleagueIDs,
}: {
    filterByNameAndEmail?: string
    limit?: number
    nextToken?: NextToken
    companyID?: string
    officeData?: Array<MultiselectItem>
    departmentData?: Array<MultiselectItem>
    isManager?: boolean
    noStatusesFilter?: boolean
    date?: string
    myFavouriteColleagueIDs?: string[]
} & RequestCommonArgs) => {
    let and = [] as Array<{
        or: Array<
            | DepartmentIdsFilter
            | FavouriteColleagueIDsFilter
            | OfficeIdsFilter
            | FirstNameFilter
            | LastNameFilter
            | FullNameFilter
            | EmailFilter
        >
    }>

    const orFilters: Array<
        | DepartmentIdsFilter
        | FavouriteColleagueIDsFilter
        | OfficeIdsFilter
        | FirstNameFilter
        | LastNameFilter
        | FullNameFilter
        | EmailFilter
    > = []

    if (departmentData?.length) {
        orFilters.push(
            ...departmentData.map((item) => ({
                departmentIDsString: { wildcard: `*${getSelectItemKey(item, 'id').replace(/-/g, '')}*` },
            })),
        )
    }

    if (myFavouriteColleagueIDs?.length) {
        orFilters.push(
            ...myFavouriteColleagueIDs.map((id) => ({
                id: {
                    eq: id,
                },
            })),
        )
    }
    if (orFilters.length > 0) {
        and.push({ or: orFilters })
    }

    if (officeData?.length) {
        and = [...and, { or: officeData.map((item) => ({ favouriteOfficeID: { eq: getSelectItemKey(item, 'id') } })) }]
    }

    if (filterByNameAndEmail) {
        const searchString = filterByNameAndEmail.toLowerCase().replace('@', '').split(' ')
        and = [
            ...and,
            {
                or: [
                    { and: searchString.map((text) => ({ firstname: { wildcard: `${text}*` } })) },
                    { and: searchString.map((text) => ({ lastname: { wildcard: `${text}*` } })) },
                    { and: searchString.map((text) => ({ fullNameLowerCase: { wildcard: `${text}*` } })) },
                    { and: searchString.map((text) => ({ emailForSearch: { wildcard: `${text}*` } })) },
                ],
            },
        ]
    }

    let filterByStatuses = undefined as MultiselectItem[] | undefined

    if (!isManager) {
        filterByStatuses = [statusesList[0]]
    } else if (!filterByNameAndEmail) {
        filterByStatuses = statuses || undefined
    }
    if (!noStatusesFilter) {
        filterByStatuses = undefined
    }

    return {
        weekNumber,
        date,
        year: moment(startWeekDate).year(),
        limit: limit || LimitByDepartment,
        nextToken,
        filter: {
            companyID: { eq: companyID },
            id: { ne: employeeId },
            active: { ne: false },
            favouriteOfficeID: !favouriteOfficeId ? undefined : { eq: favouriteOfficeId },
            statusString: !filterByStatuses ? undefined : getStatusesFilters(filterByStatuses),
            and: and.length ? and : undefined,
        },
    }
}

const getStatusesFilters = (statuses) => {
    if (!statuses) {
        return undefined
    }

    let filters: StatusesFilter = {}

    /* we can find in array statuses only REGISTRED or other status */
    statuses.forEach((status) => {
        if (status.id === EmployeeStatus.REGISTRED) {
            filters = { eq: EmployeeStatus.REGISTRED }
        } else {
            filters = { ne: EmployeeStatus.REGISTRED }
        }
    })

    return filters
}
