import { ActionType, ResourceType, Scope } from '../graphql/autogenerate/schemas'
import { ERoleEmployee } from '../types/data.types'
import { EUserPermissions } from '../types/user.types'

export const defaultPermissions: {
    [key in ERoleEmployee]: EUserPermissions
} = {
    [ERoleEmployee.OWNER]: [
        {
            resourceType: ResourceType.DASHBOARD,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.ORGANIZATION,
        },
        {
            resourceType: ResourceType.EMPLOYEE,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.ORGANIZATION,
        },
        {
            resourceType: ResourceType.OFFICE,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.ORGANIZATION,
        },
        {
            resourceType: ResourceType.DEPARTMENT,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.TEAM,
        },
    ],
    [ERoleEmployee.MANAGER]: [
        {
            resourceType: ResourceType.DASHBOARD,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.ORGANIZATION,
        },
        {
            resourceType: ResourceType.EMPLOYEE,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.ORGANIZATION,
        },
        {
            resourceType: ResourceType.OFFICE,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.ORGANIZATION,
        },
        {
            resourceType: ResourceType.DEPARTMENT,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.TEAM,
        },
    ],
    [ERoleEmployee.TEAM_LEAD]: [
        {
            resourceType: ResourceType.DASHBOARD,
            allowedActions: [ActionType.VIEW],
            scope: Scope.TEAM,
        },
        {
            resourceType: ResourceType.EMPLOYEE,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.TEAM,
        },
        {
            resourceType: ResourceType.DEPARTMENT,
            allowedActions: [ActionType.VIEW, ActionType.CREATE, ActionType.EDIT, ActionType.DELETE],
            scope: Scope.TEAM,
        },
    ],
    [ERoleEmployee.EMPLOYEE]: [],
}
