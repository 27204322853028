import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import * as S from './styles'
import { InfoIconSilverSVG } from '../../../assets/common'
import { useCompanyData } from '../../../context'

const BookingRules: FC = () => {
    const { company } = useCompanyData()
    const { t } = useTranslation(['translation'])
    const rules = company?.Rules

    return (
        <>
            {rules && (
                <S.DescriptionRules>
                    <S.DescriptionRulesTitleWrapper>
                        <InfoIconSilverSVG />
                        <S.DescriptionRulesTitle>{t('Booking rules')}</S.DescriptionRulesTitle>
                    </S.DescriptionRulesTitleWrapper>
                    <S.DescriptionRulesRow>
                        <S.DescriptionRulesItemWrapper>
                            <S.DescriptionRulesItem>
                                {t('Planning horizon')}{' '}
                                <span>
                                    - {rules.horizonOfPlanningTimeAmount}{' '}
                                    {t(rules.horizonOfPlanningTimeUnit.toLowerCase())}
                                </span>
                            </S.DescriptionRulesItem>
                        </S.DescriptionRulesItemWrapper>
                    </S.DescriptionRulesRow>
                    <S.DescriptionRulesRow>
                        <S.DescriptionRulesItemWrapper>
                            <S.DescriptionRulesItem>
                                {t('Auto-renewal')}{' '}
                                <span>- {rules.denyRecurringBookingOverThePlanningHorizon ? t('OFF') : t('ON')}</span>
                            </S.DescriptionRulesItem>
                        </S.DescriptionRulesItemWrapper>
                    </S.DescriptionRulesRow>
                </S.DescriptionRules>
            )}
        </>
    )
}

export default BookingRules
