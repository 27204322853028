import { sidebarResourceMap } from 'templates/components/Sidebar/const'
import { SidebarLinks } from 'templates/components/Sidebar/types'

import { EUserPermissions } from '../types/user.types'

export function getAccessibleSidebarLinks(
    allLinks: SidebarLinks,
    resourceMap: typeof sidebarResourceMap,
    userPermissions: EUserPermissions,
): SidebarLinks {
    return Object.entries(allLinks).reduce((acc, [key, link]) => {
        const { resourceType, requiredAction } = resourceMap[key] || { resourceType: null }
        // If no resource requirement, always include
        if (!resourceType) {
            acc[key] = link
            return acc
        }
        // Check if user has a matching permission
        const hasAccess = userPermissions.some(
            (perm) =>
                perm.resourceType === resourceType && (!requiredAction || perm.allowedActions.includes(requiredAction)),
        )
        if (hasAccess) {
            acc[key] = link
        }
        return acc
    }, {} as SidebarLinks)
}
