import { MultiselectItem } from 'types/common.types'

export type FilterObject = { items?: Array<MultiselectItem> | null; isAll?: boolean }

export enum FiltersTypes {
    OFFICES = 'offices',
    TEAMS = 'teams',
    STATUSES = 'statuses',
}

export type Filters = {
    [FiltersTypes.OFFICES]: FilterObject
    [FiltersTypes.TEAMS]: FilterObject
    [FiltersTypes.STATUSES]: FilterObject
}
