import moment from 'moment'

import { Employee, AvailabilityType, RepeatType, ReservableType } from 'graphql/autogenerate/schemas'
import { findOverlap } from 'helpers/utils'
import { EAvailabilityType, PIN_CIRCLE, SeatPickerReservable, TCompany } from 'types/data.types'
import { EUserGroup } from 'types/user.types'

type Request = {
    dates: string[]
    startTime: string
    endTime: string
    employees?: Array<{ id: string; departmentIDs: string[] }>
    bookingRepeatType?: RepeatType
    daysOfWeek?: string[]
}

export const isAvailableReservable = (
    reservable: SeatPickerReservable,
    params: Request,
    company: TCompany,
    userGroup: EUserGroup,
): { selectable: boolean; available: boolean } => {
    const request = params
    const dates = request?.dates || []
    let selectable = false
    let available = false

    if (!dates.length || !reservable.id) {
        return { selectable: false, available: false }
    }

    const rules = company.Rules

    const isManager = userGroup === EUserGroup.MANAGER

    const isBookingForToday =
        ![RepeatType.WEEKLY, RepeatType.CUSTOM].includes(request.bookingRepeatType || RepeatType.NEVER) &&
        dates.length === 1 &&
        dates[0] === moment().format('YYYY-MM-DD')

    const isPersonalSeatAvailableToday = (rules?.isPersonalSeatAvailableToday && isBookingForToday) || false
    const isTeamSeatAvailableToday = (rules?.isTeamSeatAvailableToday && isBookingForToday) || false

    const employees = request.employees || []

    // If the reservable is globally unavailable, no need to check further
    if (reservable.availabilityType === (AvailabilityType.UNAVALIABLE as unknown as EAvailabilityType)) {
        selectable = false
        available = false

        return { selectable, available }
    }

    if (isUnavailable(reservable, request, isPersonalSeatAvailableToday, isTeamSeatAvailableToday, employees)) {
        selectable = false
        available = false

        if (!isManager) {
            return { selectable, available }
        }
    }

    if ((reservable.type as unknown as ReservableType) === ReservableType.SEAT) {
        available = selectable = isSeatAvailable(reservable, request)
    } else if ((reservable.type as unknown as ReservableType) === ReservableType.ZONE) {
        selectable = true
        available = true
    } else if ((reservable.type as unknown as ReservableType) === ReservableType.PARKING) {
        const bookings = (reservable.BookingReservablesByCreatedAt?.items || [])
            // .filter((b) => b.booking.employeeID !== request.employees?.[0]?.id)
            .flatMap((b) => b.booking)

        available = selectable = bookings.length === 0
    } else if ((reservable.type as unknown as ReservableType) === ReservableType.ROOM) {
        available = selectable = !checkIsFullDayRoomPreview(reservable.availabilityPreviewByDates)
    }

    if (
        isManager &&
        [ReservableType.SEAT, ReservableType.PARKING, ReservableType.ZONE].includes(
            reservable.type as unknown as ReservableType,
        ) &&
        (reservable.availabilityType as unknown as AvailabilityType) !== AvailabilityType.UNAVALIABLE
    ) {
        selectable = true
    }

    return { selectable, available }
}

const isUnavailable = (
    reservableData: SeatPickerReservable,
    request: Request,
    isPersonalSeatAvailableToday: boolean,
    isTeamSeatAvailableToday: boolean,
    employees?: Array<{ id: string; departmentIDs?: string[] }>,
) => {
    // If the reservable is booked for a specific person, check if any of the employeeIDs match
    if (
        reservableData.availabilityType === (AvailabilityType.BOOKED_FOR_PERSON as unknown as EAvailabilityType) &&
        !isPersonalSeatAvailableToday
    ) {
        // If none of the employeeIDs match the reservable's employeeID, it's considered unavailable
        return !(request.employees || []).every((employee) => {
            return reservableData.ReservableToEmployees?.items
                .map((re) => re?.employee.id || re?.employeeID || '')
                .includes(employee.id)
        })
    }

    // check if employee is in the team
    if (
        reservableData.availabilityType === (AvailabilityType.BOOKED_FOR_TEAM as unknown as EAvailabilityType) &&
        reservableData.ReservableToDepartments &&
        !isTeamSeatAvailableToday
    ) {
        return !(employees || []).every((employee) => isEmployeeInTeam(employee, reservableData))
    }

    // Add other specific checks as necessary
    return false
}

const isEmployeeInTeam = (employee: Pick<Employee, 'departmentIDs'>, reservableData: SeatPickerReservable) => {
    const departmentIDs =
        reservableData.ReservableToDepartments!.items.filter((d) => d?.Department?.id).map((d) => d!.Department!.id) ||
        []

    return findOverlap(departmentIDs, (employee.departmentIDs as string[]) || []).length > 0
}

const isSeatAvailable = (reservableData: SeatPickerReservable, request: Request) => {
    if (!reservableData) {
        return false
    }

    const bookings = reservableData.BookingsByDateAndDayOfWeek.items || []

    return bookings.length === 0
}

const checkIsFullDayRoomPreview = (previewHours?: PIN_CIRCLE[]): boolean => {
    return previewHours ? previewHours.every((hour) => hour === PIN_CIRCLE.FULL) : false
}
