import React, { Fragment, FC } from 'react'

import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { PlusSVG, EditSVG, TrashSVG } from 'assets/common'
import { useResizeDevice } from 'context'
import { MainEmployeeData } from 'types/data.types'

import * as S from './styles'
import CounterEmployees from '../EmployeeList/components/CounterEmployees'
import TooltipContainer from '../TooltipContainer'
import { EmployeeItem } from '../index'

type Props = {
    title: string
    buttonTitle: string
    handleClickOpen: (status: boolean) => void
    deleteEmployee?: (id?: string) => void
    editEmployee?: () => void
    employee?: MainEmployeeData | null
    employees?: MainEmployeeData[]
    isMulti?: boolean
    isCanEdit?: boolean
}

const AddPeople: FC<Props> = ({
    title,
    buttonTitle,
    handleClickOpen,
    employee,
    employees = [],
    editEmployee,
    deleteEmployee,
    isMulti = false,
    isCanEdit = true,
}) => {
    const { isMobile } = useResizeDevice()
    const { t } = useTranslation('translation')
    return (
        <>
            {isMulti && employees && employees?.length > 0 && (
                <div className={'ml-4'}>
                    <CounterEmployees counter={employees?.length} title={t('selected') as string}></CounterEmployees>
                </div>
            )}
            <S.TitleAddPeople>{title}</S.TitleAddPeople>
            {isMulti && (
                <div>
                    {isCanEdit && (
                        <S.ButtonBlockWrap className={'mb-8'} isHovered>
                            <S.SpaceScreenMembersAddBtn onClick={() => handleClickOpen(true)}>
                                <S.AddMemberIco>
                                    <PlusSVG />
                                </S.AddMemberIco>
                                <div>{buttonTitle}</div>
                            </S.SpaceScreenMembersAddBtn>
                        </S.ButtonBlockWrap>
                    )}

                    {employees && employees?.length > 0 && (
                        <S.ButtonBlockWrap className={'multi'}>
                            <SimpleBar
                                style={{
                                    maxHeight: '250px',
                                    width: '100%',
                                    paddingRight: '5px',
                                }}
                                autoHide={true}
                                className="custom-vertical-scroll"
                            >
                                <S.MembersWrapper>
                                    {employees &&
                                        employees?.length &&
                                        employees.filter(Boolean).map((people) => (
                                            <Fragment key={people.id}>
                                                <S.RowWrap isMobile={isMobile}>
                                                    <S.BookingEditRowTitle>
                                                        <EmployeeItem
                                                            emplName={people.fullName}
                                                            emplPhoto={people.photo}
                                                        />
                                                    </S.BookingEditRowTitle>
                                                    <TooltipContainer
                                                        title={t('Delete', { ns: 'translation' }) as string}
                                                        placement="top"
                                                        offset="0, 6px"
                                                    >
                                                        <S.TeamEventMemberDelete
                                                            className={'delete'}
                                                            onClick={() => deleteEmployee && deleteEmployee(people.id)}
                                                        >
                                                            <TrashSVG />
                                                        </S.TeamEventMemberDelete>
                                                    </TooltipContainer>
                                                </S.RowWrap>
                                            </Fragment>
                                        ))}
                                </S.MembersWrapper>
                            </SimpleBar>
                        </S.ButtonBlockWrap>
                    )}
                </div>
            )}

            {!isMulti && (
                <div>
                    {!employee ? (
                        <S.ButtonBlockWrap isHovered>
                            <S.SpaceScreenMembersAddBtn onClick={() => handleClickOpen(true)}>
                                <S.AddMemberIco>
                                    <PlusSVG></PlusSVG>
                                </S.AddMemberIco>
                                {buttonTitle}
                            </S.SpaceScreenMembersAddBtn>
                        </S.ButtonBlockWrap>
                    ) : (
                        <S.ButtonBlockWrap>
                            <S.MembersWrapper>
                                {employee && (
                                    <Fragment key={employee.id}>
                                        <S.RowWrap isMobile={isMobile}>
                                            <S.BookingEditRowTitle>
                                                <EmployeeItem emplName={employee.fullName} emplPhoto={employee.photo} />
                                            </S.BookingEditRowTitle>
                                            <div className={'df align-items-start'}>
                                                {editEmployee && (
                                                    <TooltipContainer
                                                        title={t('Edit', { ns: 'translation' }) as string}
                                                        placement="top"
                                                        offset="0, 6px"
                                                    >
                                                        <S.TeamEventMemberEdit
                                                            className={'mr-2'}
                                                            onClick={editEmployee}
                                                        >
                                                            <EditSVG></EditSVG>
                                                        </S.TeamEventMemberEdit>
                                                    </TooltipContainer>
                                                )}
                                                {deleteEmployee && (
                                                    <TooltipContainer
                                                        title={t('Delete', { ns: 'translation' }) as string}
                                                        placement="top"
                                                        offset="0, 6px"
                                                    >
                                                        <S.TeamEventMemberDelete onClick={() => deleteEmployee()}>
                                                            <TrashSVG />
                                                        </S.TeamEventMemberDelete>
                                                    </TooltipContainer>
                                                )}
                                            </div>
                                        </S.RowWrap>
                                    </Fragment>
                                )}
                            </S.MembersWrapper>
                        </S.ButtonBlockWrap>
                    )}
                </div>
            )}
        </>
    )
}

export default AddPeople
