import React, { useState, useRef, MouseEvent, useEffect, PropsWithChildren, ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import CheckBox from 'components/atoms/CheckBox'
import { getSelectItemKey } from 'helpers'
import { MultiselectItem } from 'types/common.types'

import * as S from './styles'
import { useResizeDevice } from '../../../context'

type Props<V> = {
    listItems: Array<V>
    onChange: (data: Array<V>, allSelected?: boolean) => void
    placeholder?: string
    defaultItems?: Array<V>
    disabled?: boolean
    className?: string
    isAllFilters?: boolean
    title: string
}

type SelectedItemsType<V> = {
    selectedItems: Array<V>
    allSelected: boolean
    defaultItem?: V
}

export const FiltersList = <V extends MultiselectItem>({
    listItems,
    onChange,
    defaultItems,
    isAllFilters,
    title,
}: PropsWithChildren<Props<V>>): ReactElement => {
    const { t, ready } = useTranslation('translation')
    const { isMobile } = useResizeDevice()
    const items = useRef(listItems)
    const [state, setState] = useState<SelectedItemsType<V>>({ selectedItems: [], allSelected: false })

    useEffect(() => {
        if (isAllFilters !== undefined) {
            let allSelected = isAllFilters

            setState((prevState) => {
                if (!isAllFilters && items.current.length === prevState.selectedItems.length) {
                    allSelected = true
                }

                return {
                    ...prevState,
                    allSelected,
                }
            })
        }
    }, [isAllFilters])

    const onSelected = (allSelectedMarker: boolean) => {
        setState((prevState) => ({
            ...prevState,
            selectedItems: allSelectedMarker ? listItems : [],
            allSelected: allSelectedMarker,
        }))

        onChange(allSelectedMarker ? listItems : [], allSelectedMarker)
    }

    const selectHandler = (key: V, selected: boolean) => {
        let data = state.selectedItems
        const id = getSelectItemKey(key, 'id')

        if (selected) {
            data = data.filter((item) => {
                return getSelectItemKey(item, 'id') !== id
            })
        } else {
            data = [...data, key]
        }

        const allSelected = data.length === listItems.length
        setState((prev) => ({ ...prev, selectedItems: data, allSelected }))
        onChange(data, allSelected)
    }

    const selectAllHandler = (e: MouseEvent<HTMLLIElement>) => {
        e.preventDefault()
        onSelected(!state.allSelected)
    }

    useEffect(() => {
        if (defaultItems !== undefined) {
            setState((prev) => ({
                ...prev,
                selectedItems: defaultItems,
                allSelected: defaultItems.length === listItems.length,
            }))
        }
    }, [defaultItems])

    if (!ready) {
        return <></>
    }

    return (
        <S.FiltersColumn isMobile={isMobile}>
            <S.FiltersListHeader>
                <S.FilterTitle>{title}</S.FilterTitle>
                {state.selectedItems && state.selectedItems.length && !state.allSelected ? (
                    <S.FiltersListHeaderHint>
                        ({state.selectedItems.length} {t('selected')})
                    </S.FiltersListHeaderHint>
                ) : null}
            </S.FiltersListHeader>
            <S.ItemsList role="listbox" tabIndex={-1}>
                <S.DropDownItem
                    onClick={selectAllHandler}
                    role="option"
                    aria-label="Select All Rows checkbox"
                    aria-selected={state.allSelected}
                    className="select-all"
                >
                    <CheckBox name="Select all" label={t('Select all') as string} checked={state.allSelected} />
                </S.DropDownItem>
                <S.ItemsScrollList isMobile={isMobile}>
                    {listItems.map((item) => {
                        const currentItemKey = getSelectItemKey(item, 'id')
                        const currentItemTitle = getSelectItemKey(item)
                        const selected =
                            state.allSelected ||
                            state.selectedItems.some(
                                (selectedItem) => getSelectItemKey(selectedItem, 'id') === currentItemKey,
                            )

                        return (
                            <S.DropDownItem
                                onClick={(e) => {
                                    e.preventDefault()
                                    selectHandler(item, selected)
                                }}
                                role="option"
                                aria-selected={selected}
                                key={currentItemKey}
                            >
                                <CheckBox name={currentItemTitle} label={currentItemTitle} checked={selected} />
                            </S.DropDownItem>
                        )
                    })}
                </S.ItemsScrollList>
            </S.ItemsList>
        </S.FiltersColumn>
    )
}
