import styled from 'styled-components'

export const EmployeeList = styled.div`
    height: calc(100% - 100px);
    position: relative;
    margin: 0 0 25px;

    ul {
        padding: 0;

        .employee-item {
            cursor: pointer;
            padding: 8px 16px;

            &:hover {
                background-color: rgba(227, 232, 238, 0.5);
            }
        }
    }

    .simplebar-track.simplebar-vertical {
        right: 5px;
        bottom: 24px;
    }
`

export const EmployeeMe = styled.div`
    cursor: pointer;
    margin: 12px 0;
    .employee-item {
        cursor: pointer;
        padding: 8px 16px;

        &:hover {
            background-color: rgba(227, 232, 238, 0.5);
        }
    }
`

export const FiltersWrapper = styled.div`
    width: 100%;
    display: flex;
    height: 48px;
    padding: 0 16px;

    & .search_icon {
        fill: #929495;
    }
`

export const Filters = styled.div`
    width: 100%;
    display: flex;
    padding: 0 8px;
    background: rgba(227, 232, 238, 0.5);
    border-radius: 12px;
`

export const LoaderWrap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
`
