import styled from 'styled-components'
export const SelectUserWrapper = styled.div`
    position: relative;
    z-index: 1;
`
export const SelectUserIcon = styled.div`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-7.5px);
    z-index: -1;
    & svg {
        fill: #999999;
    }
`
