import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ENotificationChannel, ERoleEmployee, TCompany } from 'types/data.types'
import { EUserLocale, EUserPermissions, EUserShareAttendance, User, UserResponse } from 'types/user.types'

import { defaultPermissions } from '../../shared/defaultPermissions'

const initialState: User = {
    id: '',
    favouriteOfficeID: '',
    favouriteReservableID: '',
    firstname: '',
    lastname: '',
    fullName: '',
    cognitoUserId: '',
    email: '',
    photo: null,
    role: ERoleEmployee.EMPLOYEE,
    departmentIDs: [],
    favouriteColleagueIDs: [],
    buddyID: '',
    // departments: [],
    position: null,
    office: null,
    demoAccountID: null,
    notificationChannel: ENotificationChannel.EMAIL,
    locale: EUserLocale.EN,
    shareAttendance: EUserShareAttendance.ALL,
    isAdmin: false,
    turnOnExperimentalFeatures: false,
    permissions: defaultPermissions[ERoleEmployee.EMPLOYEE],
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userDataReceived: (state, action: PayloadAction<{ employee: UserResponse; company?: TCompany }>) => {
            const {
                // departments,
                departmentIDs,
                favouriteColleagueIDs,
                buddyID,
                Office,
                Position,
                notificationChannels,
                firstname,
                lastname,
                locale,
                shareAttendance,
                role,
                CustomRole,
                demoAccountID,
                favouriteReservableID,
                turnOnExperimentalFeatures,
                ...other
            } = action.payload.employee

            const CustomsRoles = action.payload.company?.CustomRoles?.items || []

            let permissions = defaultPermissions[role || ERoleEmployee.EMPLOYEE]

            const RoleWithPermissions = CustomsRoles.find(
                (customRole) =>
                    customRole.id === action.payload.employee.CustomRole?.id ||
                    customRole.name === action.payload.employee.role,
            )

            if (RoleWithPermissions) {
                permissions = (RoleWithPermissions.Permissions?.items as EUserPermissions) || []
            }

            return {
                ...other,
                firstname,
                lastname,
                turnOnExperimentalFeatures,
                locale: locale || EUserLocale.EN,
                shareAttendance: shareAttendance || EUserShareAttendance.ALL,
                departmentIDs: departmentIDs || [],
                favouriteColleagueIDs: favouriteColleagueIDs || [],
                favouriteReservableID: favouriteReservableID,
                buddyID: buddyID,
                // departments: departments || [],
                position: Position,
                office: Office,
                demoAccountID: demoAccountID,
                notificationChannel:
                    notificationChannels === null ? state.notificationChannel : notificationChannels[0],
                fullName: `${firstname} ${lastname}`,
                role,
                permissions: permissions,
                isAdmin: role === ERoleEmployee.MANAGER || role === ERoleEmployee.OWNER,
            }
        },
        userDataCleared: () => initialState,
    },
})

export const userActions = userSlice.actions
export default userSlice.reducer
