import { ERoleEmployee } from '../types/data.types'

export const PolicyUrl = 'https://www.yoffix.com/privacy-policy'
export const TermsUrl = 'https://www.yoffix.com/terms-of-use'
export const CheckoutReturnUrl = 'https://checkout.paddle.com/checkout'

export const DefaultSalesEmail = 'sales@yoffix.com'

export const YoffixSlackUrl = 'https://api.slack.com/apps/A029Q40PEDT/install-on-team'

export const JwtTokenName = 'auth:token'

export const nonBusinessDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'msn.com',
    'live.com',
    'outlook.com',
    'icloud.com',
    'me.com',
    'mail.com',
    'inbox.com',
    'gmx.com',
    'fastmail.com',
    'zoho.com',
    'yandex.com',
    'protonmail.com',
    'email.com',
    'rocketmail.com',
    'bellsouth.net',
    'charter.net',
    'comcast.net',
    'cox.net',
    'earthlink.net',
    'juno.com',
    'btinternet.com',
    'virginmedia.com',
    'blueyonder.co.uk',
    'freeserve.co.uk',
    'live.co.uk',
]

export const defaultRoles: Array<ERoleEmployee | string> = [
    ERoleEmployee.OWNER,
    ERoleEmployee.MANAGER,
    ERoleEmployee.TEAM_LEAD,
    ERoleEmployee.EMPLOYEE,
]
