import styled, { css } from 'styled-components'

export const ButtonBlockWrap = styled.div<{ isHovered?: boolean }>`
    width: 100%;
    position: relative;
    padding: 8px 8px 0;
    cursor: pointer;
    user-select: none;
    &.multi {
        padding: 0 8px;
    }
`

export const RowWrap = styled.div<{ isMobile?: boolean }>`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    &:hover {
        .delete {
            opacity: 1;
        }
    }

    ${(props) =>
        props.isMobile &&
        css`
            :first-child {
                border-top: none;
            }
        `}
`

export const ButtonRowWrap = styled(RowWrap)`
    padding: 20px 0;
`

export const SpaceScreenMembersAddBtn = styled.span`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.color.activeSpacePanel};
    cursor: pointer;
`
export const AddMemberIco = styled.div`
    margin-right: 9px;
    line-height: 0;
    svg {
        fill: #0019ff;
    }
`
export const MembersWrapper = styled.div`
    margin-top: -8px;
    margin-bottom: 10px;
`

export const SpaceScreenMembersHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`

export const SpaceScreenMembersTitleWrap = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    align-items: center;

    svg {
        width: 30px;
        height: auto;
    }

    h4 {
        margin: 0 5px 0 9px;
        font-weight: 500;
        font-size: 14px;
    }
`
export const BookingEditRowTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(51, 51, 51, 0.8);

    &.sub-tittle {
        color: ${(props) => props.theme.color.secondaryBlack};
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
`

export const BookingEditInputWrap = styled.div`
    width: 148px;
    height: 39px;
`
export const TeamEventMemberDelete = styled.div`
    width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    &.delete {
        opacity: 0;
    }

    & svg {
        fill: ${(props) => props.theme.color.negative_red_80};
        fill-opacity: 1;
        cursor: pointer;
    }
`

export const TeamEventMemberEdit = styled.div`
    width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        fill-opacity: 1;
        cursor: pointer;
        path {
            fill: ${(props) => props.theme.color.secondaryBlack};
        }
    }
`

export const TitleAddPeople = styled.div`
    color: ${(props) => props.theme.color.secondary_black_50};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 12px;
`
