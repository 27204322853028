import React, { memo } from 'react'

import DropDown, { Props as DropDownProps } from 'components/atoms/DropDown'
import { MultiselectItem } from 'types/common.types'

import * as S from './style'
import { ExternalLabel } from '../CustomInputV2/styles'
import DropdownArrowIcon from '../DropdownArrowIcon'
import DropdownArrowIconSmall from '../DropdownArrowIconSmall'

type CustomDropdownProps<ItemType> = DropDownProps<ItemType> & {
    label?: string | null
    emptyLabel?: boolean
    withOutMargin?: boolean
    size?: 'small' | 'medium'
    offBackground?: boolean
}

const CustomDropdown = <ItemType extends MultiselectItem>({
    withOutMargin,
    label,
    emptyLabel,
    placeholder,
    size,
    offBackground,
    ...props
}: CustomDropdownProps<ItemType>) => {
    const dropDownStyles = {
        borderRadius: 12,
        background: 'rgba(227, 232, 238, 255)',
        left: -40,
        li: {
            height: `${size === 'small' ? '40px' : '56px'}`,
        },
    }

    return (
        <S.Root withOutMargin={withOutMargin}>
            {(label || emptyLabel) && <ExternalLabel empty={emptyLabel}>{label || 'empty'}</ExternalLabel>}
            <S.CustomDropdownContainer data-label="" size={size} offBackground={offBackground}>
                <DropDown
                    {...props}
                    placeholder={placeholder}
                    styles={dropDownStyles}
                    IconComponent={size === 'small' ? DropdownArrowIconSmall : DropdownArrowIcon}
                    DropDownItem={S.DropDownItem}
                />
            </S.CustomDropdownContainer>
        </S.Root>
    )
}

export default memo(CustomDropdown)
