import { useCallback, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import moment from 'moment/moment'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { spaceScreenActions } from 'containers/SpaceScreen/slices/spaceScreen'
import { useCompanyData } from 'context'
import { checkPersonTeamAbilitySeat } from 'helpers'
import { useAppDispatch, useAppSelector } from 'hooks'
import { messageActions } from 'store/slices/message'
import { ERoleEmployee, SeatPickerReservable } from 'types/data.types'

import { useCrudBooking } from './useCrudBooking'
import useSubscriptionsSeatData, {
    useSubscriptionsCreateSeatData,
    useSubscriptionsDeleteSeatData,
    useSubscriptionsUpdateSeatData,
} from './useSubscriptionsSeatData'
import { findRelevantBooking } from '../../../helpers'
import { BookingEventTypeMode, BookingType, EmployeeData, EventTypes } from '../../../types'
import { GET_BOOKING_BY_RESERVABLE_ID } from '../graphql'
import { getSuggestedTimeSlot } from '../helpers'

type UseSpaceBookingsProps = {
    companyID: string
}

export const useLoadBookings = ({ companyID }: UseSpaceBookingsProps) => {
    const dispatch = useAppDispatch()
    const { company } = useCompanyData()
    const [availableParkingSlots, setAvailableParkingSlots] = useState<number | null>(null)
    const [isFirstOpen, setIsFirstOpen] = useState(true)
    const [isUnavailableSeat, setIsUnavailableSeat] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { getBookingById } = useCrudBooking()

    const [searchParams] = useSearchParams()
    const isCheckInFlow = searchParams.get('checkin') === 'true'

    const userId = useAppSelector(({ user }) => user.id)
    const userDepartments = useAppSelector(({ user }) => user.departmentIDs)
    const userRole = useAppSelector(({ user }) => user.role)
    const userPhoto = useAppSelector(({ user }) => user.photo)
    const userFullName = useAppSelector(({ user }) => user.fullName)
    const userFirstname = useAppSelector(({ user }) => user.firstname)
    const userLastname = useAppSelector(({ user }) => user.lastname)
    const userPosition = useAppSelector(({ user }) => user.position)

    const isEmployee = userRole === ERoleEmployee.EMPLOYEE

    const bookingsFromState = useAppSelector((state) => state.spaceScreen.bookings)
    const selectedBooking = useAppSelector((state) => state.spaceScreen.selectedBooking)
    const dates = useAppSelector((state) => state.spaceScreen.date)
    const repeatDate = useAppSelector((state) => state.spaceScreen.repeatDate)
    const repeatDaysOfWeek = useAppSelector((state) => state.spaceScreen.repeatDaysOfWeek)
    const selectedSpaceID = useAppSelector((state) => state.spaceScreen.selectedSpaceID)
    const selectedReservableID = useAppSelector((state) => state.spaceScreen.selectedReservableID)
    const startSpaceWorkingTime = useAppSelector((state) => state.spaceScreen.startSpaceWorkingTime)
    const endSpaceWorkingTime = useAppSelector((state) => state.spaceScreen.endSpaceWorkingTime)
    const timeZone = useAppSelector((state) => state.spaceScreen.timeZone)
    const bookingScreenType = useAppSelector((state) => state.spaceScreen.bookingScreenType)

    const updateData = useCallback(() => {
        if (selectedReservableID && selectedSpaceID) {
            /*setIsLoading(true)
                dispatch(spaceScreenActions.setLoading(true))

                if (bookingRepeatType === MobileScheduleRepeatType.CUSTOM) {
                    let datesFilter = {}
                    const datesIterble = repeatDate.length > 0 ? repeatDate : dates
                    datesFilter = {
                        or: datesIterble.map((date) => ({
                            date: {
                                eq: date,
                            },
                        })),
                    }

                    const variables = {
                        employeeID: selectedBooking?.Employee?.id || userId,
                        reservableID: selectedReservableID || null,
                        dateFilter: datesFilter,
                        filterBooking: datesFilter,
                        dopDate: dates[0],
                        spaceID: selectedSpaceID || null,
                        daysOfWeek: repeatDaysOfWeek,
                    }
                    loadDataForManyDays({ variables }).finally()
                } else {
                    loadDataForOneDay().finally()
                }*/

            loadDataForOneDay().finally(() => setIsLoading(false))
        }
    }, [selectedReservableID, selectedSpaceID, dates, repeatDate, repeatDaysOfWeek])

    const [loadDataForOneDay] = useLazyQuery<
        {
            getSpace: { availableParkingSlots: number }
            getReservable: SeatPickerReservable & { Bookings: { items: Array<BookingType> } } & {
                bookingReservables: { items: Array<{ id?: string; booking: BookingType }> }
            }
        },
        {
            spaceID: string | null
            reservableID: string | null
            employeeID: string
            startDate: string
            dopDate: string
        }
    >(GET_BOOKING_BY_RESERVABLE_ID, {
        fetchPolicy: 'no-cache',
        variables: {
            employeeID: selectedBooking?.Employee?.id || userId,
            reservableID: selectedReservableID || null,
            startDate: dates[0],
            dopDate: dates[0],
            spaceID: selectedSpaceID || null,
        },
        onCompleted: async (data) => {
            let bookings = (data.getReservable?.Bookings.items as Array<BookingType>) || []

            if (bookingScreenType === EventTypes.PARKING_BOOKING) {
                bookings = data.getReservable.bookingReservables.items.map((item) => ({
                    ...item.booking,
                    bookingReservableID: item.id,
                }))
            }

            dispatch(spaceScreenActions.setBookings({ [dates[0]]: bookings }))
            setAvailableParkingSlots(data.getSpace.availableParkingSlots)

            const userBooking = findRelevantBooking(bookings, userId)

            if (userBooking && isFirstOpen) {
                dispatch(spaceScreenActions.selectBooking({ id: userBooking.id, date: dates[0] }))
            }

            // check unavailable reservable
            const reservable = data.getReservable as SeatPickerReservable
            const isUnavailable =
                isEmployee &&
                !!checkPersonTeamAbilitySeat(
                    reservable,
                    userId,
                    userDepartments || [],
                    company?.Rules?.isPersonalSeatAvailableToday,
                    company?.Rules?.isTeamSeatAvailableToday,
                    dates.length === 1 && dates[0] === new Date().toISOString().slice(0, 10),
                )

            setIsUnavailableSeat(isUnavailable)

            // suggest booking for employee
            if (userId && dates.length === 1 && !selectedBooking && !userBooking && isFirstOpen && !isUnavailable) {
                const suggestedTimeSlot = getSuggestedTimeSlot(
                    startSpaceWorkingTime,
                    endSpaceWorkingTime,
                    bookings,
                    dates[0],
                    timeZone,
                )

                if (isCheckInFlow && (!suggestedTimeSlot || !suggestedTimeSlot?.startTime)) {
                    if (moment(suggestedTimeSlot?.startTime).hour() !== moment().hour() - 1) {
                        dispatch(spaceScreenActions.setOpenBookingErrorScreen(true))
                        dispatch(spaceScreenActions.setBackdrop(true))
                        return
                    }
                }

                if (suggestedTimeSlot) {
                    dispatch(
                        spaceScreenActions.addBooking({
                            id: uuidv4(),
                            startTime: suggestedTimeSlot.startTime,
                            endTime: suggestedTimeSlot.endTime,
                            mode: BookingEventTypeMode.CREATE,
                            date: dates[0],
                            Employee: {
                                id: userId,
                                firstname: userFirstname,
                                lastname: userLastname,
                                fullName: userFullName,
                                photo: userPhoto || '',
                                departmentIDs: userDepartments || [],
                            },
                        }),
                    )
                }
            }

            setIsFirstOpen(false)
            setIsLoading(false)
        },
        onError: (err) => dispatch(messageActions.messageShown({ text: err.message, severity: 'error' })),
    })

    useEffect(() => {
        if (bookingScreenType === EventTypes.BOOKING || bookingScreenType === EventTypes.PARKING_BOOKING) {
            setIsLoading(true)
            updateData()
        }
    }, [selectedReservableID, selectedSpaceID, dates])

    const subscribeCreate = (event) => {
        setIsLoading(true)
        getBookingById({
            variables: {
                bookingID: event.id,
            },
        })
            .then(({ data }) => {
                const booking = data?.getBooking
                if (booking) {
                    const bookingState = JSON.parse(JSON.stringify(bookingsFromState[dates[0]]))
                    bookingState.push(booking as BookingType)

                    dispatch(spaceScreenActions.setBookings({ [dates[0]]: bookingState }))
                }
            })
            .finally(() => setIsLoading(false))
    }
    const subscribeDelete = (event) => {
        setIsLoading(true)
        const bookingState = bookingsFromState[dates[0]].filter((booking) => booking.id !== event.id)
        dispatch(spaceScreenActions.setBookings({ [dates[0]]: bookingState }))
        setIsLoading(false)
    }

    const subscribeUpdate = (event) => {
        setIsLoading(true)
        bookingsFromState[dates[0]].forEach((booking) => {
            if (booking.id === event.id) {
                return { booking, ...event }
            }
        })

        dispatch(spaceScreenActions.setBookings({ [dates[0]]: bookingsFromState[dates[0]] }))
        setIsLoading(false)
    }

    useSubscriptionsCreateSeatData(companyID, selectedSpaceID, selectedReservableID, subscribeCreate)
    useSubscriptionsDeleteSeatData(companyID, selectedSpaceID, selectedReservableID, subscribeDelete)
    useSubscriptionsUpdateSeatData(companyID, selectedSpaceID, selectedReservableID, subscribeUpdate)
    useSubscriptionsSeatData(companyID, selectedSpaceID, selectedReservableID, updateData)

    const organizer: EmployeeData = {
        id: userId,
        firstname: userFirstname,
        lastname: userLastname,
        fullName: userFullName,
        photo: userPhoto || '',
        Position: userPosition || undefined,
        departmentIDs: userDepartments || [],
        role: userRole,
    }

    return {
        bookings: bookingsFromState,
        availableParkingSlots,
        selectedBooking,
        dates,
        from: startSpaceWorkingTime,
        to: endSpaceWorkingTime,
        organizer,
        isLoading,
        isManager: userRole === ERoleEmployee.MANAGER,
        bookingScreenType,
        isUnavailableSeat,
        selectedSpaceID,
    }
}
