import { EventInput } from '@fullcalendar/core'
import { createSlice, PayloadAction, ActionReducerMapBuilder, Draft, createEntityAdapter } from '@reduxjs/toolkit'
import moment from 'moment'

import { ReservableSpaceScreen } from 'components/organisms/Reservable'
import {
    Booking,
    Reservable,
    Space,
    RepeatType as MobileScheduleRepeatType,
    CalendarEvents,
    EquipmentItemModel,
    ListBookingsByEmployeeAndMomentQuery,
    ListCalendarEventsByEmployeeAndMomentQuery,
} from 'graphql/autogenerate/schemas'
import { getAWSFormatDate, getConvertedEngLocaleDate } from 'helpers'
import { MainEmployeeData, EReservableType } from 'types/data.types'

import {
    fetchCalendarEventsByEmployeeAndDate,
    fetchCurrentBookingById,
    fetchEmployeeScheduleByEmployeeId,
    fetchParkingById,
    fetchReservableById,
    fetchReservableRoomById,
} from './editBookingThunks'
import { initialStateProps } from '../types'

import type { FormValues, MeetingFormValues } from 'components/widgets/CalendarInfoDialog/types'

type SelectWeekNumberType = {
    weekNumber: number
    year: number
}

// const eventsAdapter = createEntityAdapter<EventInput>({
//     selectId: (event) => event.id || '',
// })

const initialState: initialStateProps = {
    isFormLoading: false,
    date: getAWSFormatDate(moment()),
    year: moment().year(),
    weekNumber: moment().isoWeek(),
    scheduleBookings: [],
    scheduleCalendarEvents: [],
    events: [],
    calendarEvents: [],
    placeBookings: [],
    reservables: [],
    selectedEventId: null, // selected event on Timeline
    open: false,
    startWorkTime: '08:00',
    endWorkTime: '24:00',
    employeeID: '',
    currentReservable: null,
    suggestedSeat: null,
    loadingTimeLine: false,
    loadingReservableById: false,
    loadingEmployeeSchedule: false,
    loadingEmployeeCalendarEvents: false,
    isTimelineLoaded: false,
    loadingBookingInfo: false,
    suggestedSpace: null,
    currentSpace: null,
    currentSpaceForParking: null,
    bookingRepeatType: MobileScheduleRepeatType.NEVER,
    repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(getAWSFormatDate(moment())), 'dddd')],
    selectedTeamEventMembers: [],
    selectedEmployee: null,
    checkInStatus: false,
    selectedBookingID: null, // pre-selected booking for initial load
    currentBooking: null,
    editFormValue: null,
    editMeetingFormValue: null,
    repeatDate: [], // for custom repeat
    isSpaceMapLoading: false,
    spaceLoader: false,
    selectedBookingTime: ['00:00', '24:00'],
    availableTimeForBooking: ['00:00', '24:00'],
    editBookingPopup: false,
    isEditMode: false,
    /* Reservable */
    isSeatAvailable: true,
    isNotAvailableSeatReason: '',
    suggestedSeatID: null,
    /* Parking */
    isParking: false,
    parkingMapStatus: false,
    currentParkingReservable: null,
    isParkingAvailable: true,
    isNotAvailableParkingReason: '',
    /* Space Screen */
    reservableID: null, // selected reservable on Space Screen
    currentRoomEvent: null,
    openMapState: true,
    isMeetingPlace: false,
    /* Room Screen */
    loadingRoomTimeLine: false,
    roomBookings: [],
    roomEvents: [], // Converted Room Events for Room Screen
    equipmentItems: [],
}

const editBookingSlice = createSlice({
    name: 'editBooking',
    initialState,
    reducers: {
        init: (state, action: PayloadAction<Partial<initialStateProps>>) => {
            return {
                ...state,
                ...action.payload,
            }
        },

        setIsFormLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isFormLoading: action.payload,
            }
        },

        selectCurrentReservable: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                currentReservable: action.payload,
                isMeetingPlace: action.payload?.typeReservable === EReservableType.ROOM,
            }
        },

        selectSuggestedSeat: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                suggestedSeat: action.payload,
            }
        },

        setDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
            }
        },

        selectDate: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                date: action.payload,
                currentBooking:
                    (state.currentBooking?.startTime || '').substring(0, 10) !== action.payload
                        ? null
                        : state.currentBooking,
            }
        },

        selectWeekNumber: (state, action: PayloadAction<SelectWeekNumberType>) => {
            return {
                ...state,
                weekNumber: action.payload.weekNumber,
                year: action.payload.year,
            }
        },

        setExistingBookings: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                scheduleBookings: action.payload,
                open: true,
                checkInStatus: true,
            }
        },

        setExistingCalendarEvents: (state, action: PayloadAction<Array<CalendarEvents>>) => {
            return {
                ...state,
                scheduleCalendarEvents: action.payload,
            }
        },

        setSpace: (state, action: PayloadAction<Space>) => {
            return {
                ...state,
                startWorkTime: action.payload.workingHoursFrom,
                endWorkTime: action.payload.workingHoursTo,
                currentSpace: action.payload,
                currentReservable:
                    action.payload.officeID &&
                    state.currentSpace?.officeID &&
                    state.currentSpace?.officeID !== action.payload.officeID
                        ? null
                        : state.currentReservable, // reset previous selected reservable because it's related to the previous Office
            }
        },

        setReservables: (state, action: PayloadAction<Array<ReservableSpaceScreen>>) => {
            return {
                ...state,
                reservables: action.payload,
            }
        },

        switchEditBooking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                open: action.payload,
            }
        },

        setLoadingTimeLine: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingTimeLine: action.payload,
            }
        },

        setSpaceLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                spaceLoader: action.payload,
            }
        },

        setLoadingBookingInfo: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                loadingBookingInfo: action.payload,
            }
        },

        setSpaceMapLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isSpaceMapLoading: action.payload,
            }
        },

        setCheckInStatus: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                checkInStatus: action.payload,
            }
        },

        setBookingRepeatType: (state, action: PayloadAction<MobileScheduleRepeatType>) => {
            return {
                ...state,
                bookingRepeatType: action.payload,
                repeatDaysOfWeek: [getConvertedEngLocaleDate(moment(state.date), 'dddd')],
            }
        },

        setSelectedBookingID: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                selectedBookingID: action.payload,
            }
        },

        setSelectedEventId: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                selectedEventId: action.payload,
            }
        },

        setParking: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isParking: action.payload,
            }
        },

        setRepeatDaysOfWeek: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDaysOfWeek: action.payload,
            }
        },

        setTeamEventMembers: (state, action: PayloadAction<Array<MainEmployeeData>>) => {
            return {
                ...state,
                selectedTeamEventMembers: action.payload,
            }
        },

        setEvents(state, action: PayloadAction<EventInput[]>) {
            state.events = action.payload
        },

        addEvent(state, action: PayloadAction<EventInput>) {
            state.events.push(action.payload)
        },

        updateEvent(state, action: PayloadAction<{ id: string; event: Partial<EventInput> }>): void {
            const index = state.events.findIndex((e) => e.id === action.payload.id)
            if (index !== -1) {
                state.events[index] = { ...state.events[index], ...action.payload.event }
            }
        },

        removeEvent(state, action: PayloadAction<string>) {
            state.events = state.events.filter((e) => e.id !== action.payload)
        },

        setCalendarEvents(state, action: PayloadAction<EventInput[]>) {
            state.calendarEvents = action.payload
        },

        addCalendarEvent(state, action: PayloadAction<EventInput>) {
            state.calendarEvents.push(action.payload)
        },

        updateCalendarEvent(state, action: PayloadAction<{ id: string; event: Partial<EventInput> }>): void {
            const index = state.calendarEvents.findIndex((e) => e.id === action.payload.id)
            if (index !== -1) {
                state.calendarEvents[index] = { ...state.calendarEvents[index], ...action.payload.event }
            }
        },

        removeCalendarEvent(state, action: PayloadAction<string>) {
            state.calendarEvents = state.calendarEvents.filter((e) => e.id !== action.payload)
        },

        setCurrentBooking: (state, action: PayloadAction<Booking | null>) => {
            const payload = action.payload

            if (!payload) {
                return {
                    ...state,
                    currentBooking: null,
                    selectedBookingTime: ['00:00', '24:00'],
                    currentReservable: null,
                }
            }

            const timeStart = moment.utc(payload?.startTime || state.startWorkTime).format('HH:mm')
            const timeEnd = moment.utc(payload?.endTime || state.endWorkTime).format('HH:mm')
            const reservable = payload?.reservable

            const newState: initialStateProps = {
                ...state,
                currentBooking: payload,
                selectedBookingTime: [timeStart, timeEnd],
            }

            if (state.currentBooking?.id && state.currentBooking?.id !== 'temporary' && !payload.id) {
                newState.events = state.events.filter((e) => e.id !== 'temporary')
                newState.roomEvents = state.roomEvents.filter((e) => e.id !== 'temporary')
                newState.selectedEventId = null
            }

            if (payload?.id && payload?.id !== 'temporary') {
                newState.selectedEventId = payload.id
            }

            if ((payload.bookingType as string) === 'APPROVED') {
                newState.currentReservable = state.currentReservable
                return
            }

            if (reservable && payload?.bookingType === 'OFFICE') {
                const convertedReservable = {
                    name: reservable.name,
                    availabilityType: reservable.availabilityType,
                    id: reservable.id,
                    x: reservable.x,
                    y: reservable.y,
                    typeReservable: reservable.type,
                    nameRoom: reservable.name,
                    zonePoints: reservable?.zonePoints,
                    color: reservable?.color,
                    space: (reservable?.Space as Space) || payload?.Space || null,
                    availabilityByDatesAndTime: reservable.availabilityByDatesAndTime,
                }
                newState.currentReservable = {
                    ...convertedReservable,
                } as unknown as ReservableSpaceScreen
            } else if (payload?.bookingType !== 'OFFICE') {
                newState.currentReservable = null
                newState.isSpaceMapLoading = false
            }

            if (payload?.BookingRequest?.repeatType) {
                newState.bookingRepeatType = payload.BookingRequest?.repeatType
                newState.repeatDaysOfWeek = [getConvertedEngLocaleDate(moment(state.date), 'dddd')]

                if (payload?.BookingRequest?.daysOfWeek) {
                    newState.repeatDaysOfWeek = payload?.BookingRequest?.daysOfWeek
                }

                if (payload?.BookingRequest?.dates) {
                    newState.repeatDate = payload?.BookingRequest?.dates
                }
            } else {
                newState.bookingRepeatType = MobileScheduleRepeatType.NEVER
                if (payload?.BookingRequest?.daysOfWeek) {
                    newState.repeatDaysOfWeek = []
                }
                if (payload?.BookingRequest?.dates) {
                    newState.repeatDate = []
                }
            }

            if ((payload.reservables?.items || []).length) {
                const bookingReservable = payload.reservables?.items[0]
                newState.isParking = true
                newState.currentParkingReservable = {
                    id: bookingReservable?.reservable?.id,
                    name: bookingReservable?.reservable?.name,
                    space: bookingReservable?.reservable?.Space || null,
                    typeReservable: 'PARKING',
                } as unknown as ReservableSpaceScreen
            } else if (payload.id !== 'temporary') {
                newState.isParking = false
                newState.currentParkingReservable = null
            }

            return newState
        },

        changeCurrentBooking: (state, action: PayloadAction<Booking>) => {
            const payload = action.payload
            if (!payload) {
                return {
                    ...state,
                    currentBooking: null,
                    selectedBookingTime: ['00:00', '24:00'],
                    currentReservable: null,
                }
            }

            const timeStart = moment.utc(payload?.startTime || state.startWorkTime).format('HH:mm')
            const timeEnd = moment.utc(payload?.endTime || state.endWorkTime).format('HH:mm')

            return {
                ...state,
                currentBooking: action.payload,
                selectedBookingTime: [timeStart, timeEnd],
            }
        },

        setFormEdit: (state, action: PayloadAction<FormValues>) => {
            return {
                ...state,
                editFormValue: action.payload,
            }
        },

        setMeetingFormEdit: (state, action: PayloadAction<MeetingFormValues>) => {
            return {
                ...state,
                editMeetingFormValue: {
                    ...state.editMeetingFormValue,
                    ...action.payload,
                },
            }
        },

        setCurrentRoomEvent: (state, action: PayloadAction<Partial<CalendarEvents> | null>) => {
            state.currentRoomEvent = action.payload as CalendarEvents
            if (action.payload?.startTime && action.payload?.endTime) {
                state.selectedBookingTime = [
                    moment.utc(action.payload?.startTime || state.startWorkTime).format('HH:mm'),
                    moment.utc(action.payload?.endTime || state.endWorkTime).format('HH:mm'),
                ]
            } else {
                state.selectedBookingTime = ['00:00', '24:00']
            }
        },

        updateCurrentRoomEvent: (state, action: PayloadAction<Partial<CalendarEvents> | null>) => {
            state.currentRoomEvent = {
                ...(state.currentRoomEvent as CalendarEvents),
                ...action.payload,
            }

            if (action.payload?.startTime && action.payload?.endTime) {
                state.selectedBookingTime = [
                    moment.utc(action.payload?.startTime || state.startWorkTime).format('HH:mm'),
                    moment.utc(action.payload?.endTime || state.endWorkTime).format('HH:mm'),
                ]
            } else {
                state.selectedBookingTime = ['00:00', '24:00']
            }
        },

        setEdit: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isEditMode: action.payload,
            }
        },

        selectRepeatDate: (state, action: PayloadAction<Array<string>>) => {
            return {
                ...state,
                repeatDate: action.payload,
            }
        },

        setSelectedEmployee: (state, action: PayloadAction<MainEmployeeData | null>) => {
            return {
                ...state,
                selectedEmployee: action.payload,
            }
        },

        setScheduleForReservable: (state, action: PayloadAction<Array<Booking>>) => {
            return {
                ...state,
                placeBookings: action.payload,
            }
        },

        setIsSeatAvailable: (
            state,
            action: PayloadAction<{ isSeatAvailable: boolean; isNotAvailableSeatReason: string }>,
        ) => ({
            ...state,
            isSeatAvailable: action.payload.isSeatAvailable,
            isNotAvailableSeatReason: action.payload.isNotAvailableSeatReason,
        }),

        setIsParkingAvailable: (
            state,
            action: PayloadAction<{ isParkingAvailable: boolean; isNotAvailableParkingReason: string }>,
        ) => ({
            ...state,
            isParkingAvailable: action.payload.isParkingAvailable,
            isNotAvailableParkingReason: action.payload.isNotAvailableParkingReason,
        }),

        /*Parking*/
        selectCurrentReservableParking: (state, action: PayloadAction<ReservableSpaceScreen | null>) => {
            return {
                ...state,
                currentParkingReservable: action.payload,
            }
        },

        setParkingMap: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                parkingMapStatus: action.payload,
            }
        },

        afterSaveEvent: (state) => {
            return {
                ...state,
                loadingBookingInfo: false,
                currentBooking: null,
                checkInStatus: false,
                loadingTimeLine: true,
                roomEvents: [],
                placeBookings: [],
            }
        },

        /*TEAMEVENTTIMELINE*/

        setReservableId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                reservableID: action.payload,
                openMapState: false,
            }
        },

        setMapState: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                openMapState: action.payload,
            }
        },

        setSelectedEquipment: (state, action: PayloadAction<Array<EquipmentItemModel>>) => {
            return {
                ...state,
                equipmentItems: action.payload,
                // currentReservable: {
                //     ...state.currentReservable,
                //     equipmentItems: action.payload || [],
                // },
            }
        },

        setRoomEvents(state, action: PayloadAction<EventInput[]>) {
            state.roomEvents = action.payload
        },

        addRoomEvent(state, action: PayloadAction<EventInput>) {
            state.roomEvents.push(action.payload)
        },

        updateRoomEvent(state, action: PayloadAction<{ id: string; event: Partial<EventInput> }>): void {
            const index = state.roomEvents.findIndex((e) => e.id === action.payload.id)
            if (index !== -1) {
                state.roomEvents[index] = { ...state.roomEvents[index], ...action.payload.event }
            }
        },

        removeRoomEvent(state, action: PayloadAction<string>) {
            state.roomEvents = state.roomEvents.filter((e) => e.id !== action.payload)
        },

        reset: (state) => ({
            ...initialState,
            // don't reset working time
            // employeeID: state.employeeID,
            startWorkTime: state.startWorkTime,
            endWorkTime: state.endWorkTime,
            // date: state.date,
        }),
    },
    extraReducers: (builder: ActionReducerMapBuilder<initialStateProps>) => {
        builder
            .addCase(fetchCurrentBookingById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    loadingTimeLine: true,
                }
            })
            .addCase(
                fetchCurrentBookingById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<Booking>) => {
                    const formattedDate = moment(action.payload.startTime).format('YYYY-MM-DD') // Формат даты: ГГГГ-ММ-ДД
                    return {
                        ...state,
                        currentBooking: action.payload,
                        date: formattedDate,
                    }
                },
            )
            .addCase(fetchCurrentBookingById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                return {
                    ...state,
                }
            })

        // Fetch Reservable by id
        builder
            .addCase(fetchReservableById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    placeBookings: [],
                    loadingReservableById: true,
                    isTimelineLoaded: false,
                }
            })
            .addCase(
                fetchReservableById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<Reservable>) => {
                    const bookings = action.payload?.BookingsByDateAndDayOfWeek?.items
                    state.placeBookings = bookings as Booking[]
                    state.loadingReservableById = false
                    // Check if both thunks have completed
                    if (!state.loadingEmployeeSchedule) {
                        state.isTimelineLoaded = true
                    }
                },
            )
            .addCase(fetchReservableById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                state.loadingReservableById = false
                // Depending on requirements, you might set isTimelineLoaded to false or true
                if (!state.loadingEmployeeSchedule) {
                    state.isTimelineLoaded = true // Or false if failure should prevent loading
                }
            })

        builder
            .addCase(fetchParkingById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    placeBookings: [],
                    loadingReservableById: true,
                    isTimelineLoaded: false,
                }
            })
            .addCase(
                fetchParkingById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<Reservable>) => {
                    const bookings = action.payload?.BookingReservablesByCreatedAt?.items.map((item) => ({
                        ...item?.booking,
                        bookingReservableID: item?.id,
                    }))
                    state.placeBookings = bookings as Booking[]
                    state.loadingReservableById = false
                    // Check if both thunks have completed
                    if (!state.loadingEmployeeSchedule) {
                        state.isTimelineLoaded = true
                    }
                },
            )
            .addCase(fetchParkingById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                state.loadingReservableById = false
                // Depending on requirements, you might set isTimelineLoaded to false or true
                if (!state.loadingEmployeeSchedule) {
                    state.isTimelineLoaded = true // Or false if failure should prevent loading
                }
            })

        // Fetch Room by id
        builder
            .addCase(fetchReservableRoomById.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    loadingRoomTimeLine: true,
                    loadingTimeLine: false,
                }
            })
            .addCase(
                fetchReservableRoomById.fulfilled,
                (state: Draft<initialStateProps>, action: PayloadAction<CalendarEvents[]>) => {
                    const bookings = action.payload
                    return {
                        ...state,
                        roomBookings: bookings,
                        loadingRoomTimeLine: false,
                        loadingTimeLine: false,
                    }
                },
            )
            .addCase(fetchReservableRoomById.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                return {
                    ...state,
                    loadingRoomTimeLine: false,
                    loadingTimeLine: false,
                }
            })

        // Fetch Employee Schedule by Employee ID
        builder
            .addCase(fetchEmployeeScheduleByEmployeeId.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    scheduleBookings: [],
                    loadingEmployeeSchedule: true,
                    isTimelineLoaded: false,
                }
            })
            .addCase(
                fetchEmployeeScheduleByEmployeeId.fulfilled,
                (
                    state: Draft<initialStateProps>,
                    action: PayloadAction<
                        NonNullable<
                            NonNullable<
                                ListBookingsByEmployeeAndMomentQuery['listBookingsByEmployeeAndStartTime']
                            >['items']
                        >
                    >,
                ) => {
                    const bookings = action.payload
                    state.scheduleBookings = bookings as Booking[]
                    state.loadingEmployeeSchedule = false
                    // Check if both thunks have completed
                    if (!state.loadingReservableById) {
                        state.isTimelineLoaded = true
                    }
                },
            )
            .addCase(fetchEmployeeScheduleByEmployeeId.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                state.loadingEmployeeSchedule = false
                // Depending on requirements, you might set isTimelineLoaded to false or true
                if (!state.loadingReservableById) {
                    state.isTimelineLoaded = true // Or false if failure should prevent loading
                }
            })

        // Fetch Employee Calendar Events
        builder
            .addCase(fetchCalendarEventsByEmployeeAndDate.pending, (state: Draft<initialStateProps>) => {
                return {
                    ...state,
                    scheduleCalendarEvents: [],
                    loadingEmployeeCalendarEvents: true,
                }
            })
            .addCase(
                fetchCalendarEventsByEmployeeAndDate.fulfilled,
                (
                    state: Draft<initialStateProps>,
                    action: PayloadAction<
                        NonNullable<
                            NonNullable<
                                ListCalendarEventsByEmployeeAndMomentQuery['listCalendarEventEmployeesByEmployeeAndWeekAndYear']
                            >['items']
                        >
                    >,
                ) => {
                    const links = action.payload
                    state.scheduleCalendarEvents = links.map((item) => item?.CalendarEvent) as CalendarEvents[]
                    state.loadingEmployeeCalendarEvents = false
                },
            )
            .addCase(fetchCalendarEventsByEmployeeAndDate.rejected, (state: Draft<initialStateProps>, action) => {
                console.error('Failed to fetch reservable:', action.error.message)
                state.loadingEmployeeCalendarEvents = false
            })
    },
})

export const editBookingActions = editBookingSlice.actions
export default editBookingSlice.reducer
