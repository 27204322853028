import styled from 'styled-components'

import BGImage from 'images/auth-background.png'

export const AuthRoot = styled.div`
    display: flex;
    position: relative;
    flex-direction: column-reverse;
    justify-content: start;

    min-height: 100vh;
    padding: 8px;
    background-color: ${(props) => props.theme.color.greyBackground};

    @media (min-width: 480px) and (max-width: 1024px) {
        justify-content: center;
        background-image: url(${BGImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    @media (min-width: 1024px) {
        flex-direction: row;
        padding: 12px;
    }

    & .margin_left_content {
        margin-left: 16px;
    }

    & .margin_bottom_40 {
        margin-bottom: 40px;
    }

    & .margin_bottom_0 {
        margin-bottom: 0;
    }

    & .margin_bottom_24 {
        margin-bottom: 24px;
    }

    & .margin_bottom_16 {
        margin-bottom: 16px;
    }

    & .margin_top_4 {
        margin-top: 4px;
    }

    & .margin_top_16 {
        margin-top: 16px;
    }
`

export const AuthRouteWrappContent = styled.div`
    width: 470px;
    height: max-content;
    padding: 32px 40px 24px;

    margin: 0 auto;

    background-color: ${(props) => props.theme.color.white};
    border-radius: 32px;

    @media (max-width: 480px) {
        flex: 1;
        display: flex;
        padding: 20px 24px;
        width: 100%;
        min-height: 100%;
        height: 100%;
    }

    @media (min-width: 480px) {
        width: 470px;
    }

    @media (min-width: 1024px) {
        width: 30%;
        min-width: 470px;

        height: auto;
        padding: 32px 40px 24px;
    }
`

export const AuthRouteLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-image: url(${BGImage});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 65%;

    width: 100%;
    height: calc(100vh - 24px);
    min-height: 100%;
    padding: 45px 94px 36px 72px;

    @media (max-width: 1024px) {
        display: none;
    }

    @media (min-width: 1024px) {
        height: auto;
    }
`

export const AuthRoutFormContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
`

export const AuthBottomBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: auto;
`
