import React, { FC } from 'react'

import { useResizeDevice } from 'context'

import DesktopSidebar from './components/DesktopSidebar'
import MobileSidebar from './components/MobileSidebar'

type Props = {
    pathname: string
}

const Sidebar: FC<Props> = ({ pathname }) => {
    const { isMobile } = useResizeDevice()

    return <>{isMobile ? <MobileSidebar pathname={pathname} /> : <DesktopSidebar pathname={pathname} />}</>
}

Sidebar.displayName = 'Sidebar'

export default Sidebar
