import styled, { css } from 'styled-components'

export const CustomCheckBoxRoot = styled.label<{ disabled?: boolean }>`
    color: ${(props) => props.theme.color.black};
    position: relative;
    font-size: 12px;
    padding-left: 26px;
    display: block;
    box-sizing: content-box;
    cursor: pointer;

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}

    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    input:checked ~ span {
        border: none;
        background-color: ${(props) => props.theme.color.blueButton};
    }

    span {
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
        border-radius: 5px;
        border: 2px solid ${(props) => props.theme.color.black};
        cursor: pointer;
    }

    svg {
        fill: ${(props) => props.theme.color.white};
        width: 14px;
        height: 10px;
    }
`
