import styled from 'styled-components'

export const SearchInputContainer = styled.div`
    position: relative;
    flex: 1;
    margin-right: 8px;
`

export const SearchInputLabel = styled.label`
    height: 100%;
    position: absolute;
    left: 6.5px;
    display: flex;
    align-items: center;
`

export const SearchInputField = styled.input`
    width: 100%;
    height: 100%;
    max-width: 460px;
    padding-left: 40px;
    background: inherit;
    color: ${(props) => props.theme.color.black};
    font-size: 14px;

    &::placeholder {
        color: #8d8f92;
    }

    &:focus {
        caret-color: ${(props) => props.theme.color.blueButton};
    }
`
