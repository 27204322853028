import React, { FC } from 'react'

import * as S from './styles'
import { ChevronSVG } from '../../../assets/common'
import EmployeeItem from '../EmployeeItem'

type TProps = {
    photo: string | null
    name: string
    department?: string
    onClick?: () => void
}
/*{ tabs, currentTab, setCurrentTab, theme }*/
const SelectUser: FC<TProps> = ({ ...props }) => {
    return (
        <S.SelectUserWrapper onClick={props.onClick}>
            <EmployeeItem
                emplName={props.name}
                emplPhoto={props.photo || null}
                emplDepartment={props.department}
                avatarSize={36}
                variant="div"
            />
            <S.SelectUserIcon>
                <ChevronSVG />
            </S.SelectUserIcon>
        </S.SelectUserWrapper>
    )
}

export default SelectUser
