import { useLazyQuery } from '@apollo/client'

import { useAppDispatch } from 'hooks'
import { messageActions } from 'store/slices/message'
import { Booking } from 'types'

import { GET_BOOKING_BY_ID } from '../graphql'

export const useCrudBooking = () => {
    const dispatch = useAppDispatch()

    const [getBookingById] = useLazyQuery<
        {
            getBooking: Booking
        },
        {
            bookingID: string
        }
    >(GET_BOOKING_BY_ID, {
        fetchPolicy: 'cache-and-network',
        onError: (err) => dispatch(messageActions.messageShown({ text: err.message, severity: 'error' })),
    })

    return {
        getBookingById,
    }
}
