import React, { FC } from 'react'

import * as S from './styles'
import { TeamsIconSVG } from '../../../../../assets/common'

type EmployeeListProps = {
    counter: number
    title: string
}
const CounterEmployees: FC<EmployeeListProps> = ({ counter, title }) => {
    return (
        <>
            <S.SpaceScreenMembersHeader>
                <S.SpaceScreenMembersTitleWrap>
                    <TeamsIconSVG></TeamsIconSVG>
                    <h4>{`${counter} ${title}`}</h4>
                </S.SpaceScreenMembersTitleWrap>
            </S.SpaceScreenMembersHeader>
        </>
    )
}

export default CounterEmployees
